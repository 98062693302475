import React, { useState, useEffect } from 'react'
import MainHeader from '../../../Components/HeaderQuestion/MainHeader'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router'

import manual1 from '../../../assets/Manual-1.png'
import manual2 from '../../../assets/Manual-2.png'
import manual3 from '../../../assets/Manual-3.png'
import manual4 from '../../../assets/Manual-4.png'
import manual5 from '../../../assets/Manual-5.png'
import manual6 from '../../../assets/Manual-6.png'
import manual7 from '../../../assets/Manual-7.png'
import manual8 from '../../../assets/Manual-8.png'
import manual9 from '../../../assets/Manual-9.png'
import manual10 from '../../../assets/Manual-10.png'

import api from '../../../services/api'

export default function TypeManual({ match }) {
  const url = '/form'
  const history = useHistory()

  const [selectCategory, setSelectCategory] = useState([])
  const [edit, setEdit] = useState()
  const [selected, setSelected] = useState()
  const [categorie_id, setCategorie_id] = useState()
  const [selectCategories, setSelectCategories] = useState([])
  const loadCategory = async () => {
    let response = await api.get(
      `/categories?per_page=10&page=1&columnOrder=categorie&order=asc&search=`
    )
    let obj_lookup = {}
    let array_lookup = []
    response.data.categories.map((item) => {
      obj_lookup[item.id] = item.categorie
      array_lookup.push(item.id)
    })
    setSelectCategory(obj_lookup)
  }
  useEffect(() => {
    loadCategory()
    if (edit === false) {
      setCategorie_id('')
    }
  }, [])

  function handleSubmit(id) {
    localStorage.removeItem('@BV3/idModules', id)
    localStorage.setItem('@BV3/idCategorie', id)
    history.push('/form')
  }

  return (
    <div styles={{ width: '100%' }} className="Type-manual">
      <MainHeader
        title={'Para prosseguirmos, selecione uma das opções de manual:'}
      />
      <div
        id="manuals"
        style={{
          marginTop: '144px',
          display: 'grid',
          gridAutoFlow: 'row',
          gridGap: '49px',
          gridTemplateColumns: ' repeat(auto-fit, minmax(180px, 1fr))',
          padding: '0 100px',
          // gridTemplateColumns: 'repeat(6, 1fr)',
          // gridTemplateRows: 'repeat(2, 150px)',
          // listStyle: 'none',
          // padding: '0',
          // display: 'flex',
          // gap: '49px',
          // justifyContent: 'center',
        }}
      >
        {Object.entries(selectCategory).map((manual, index) => {
          const images = [
            manual1,
            manual2,
            manual3,
            manual4,
            manual5,
            manual6,
            manual7,
            manual8,
            manual9,
            manual10,
          ]
          return (
            <div
              onClick={() => handleSubmit(manual[0])}
              style={{
                // padding: '8px 16px',
                backgroundImage: `url(${images[index]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top-left',
                backgroundRepeat: 'no-repeat',
                backgroundOrigin: 'content-box',
                borderRadius: 30,
                border: 'none',
                backgroundColor: 'white',
                width: '210.75px',
                height: '200.71px',
              }}
            >
              <label
                style={{
                  padding: '12px 16px',
                  width: '100%',
                  maxWidth: '180px',
                  display: 'inline-block',
                  fontFamily: 'Montserrat-bold',
                  color: '#263B4C',
                  fontSize: '12px',
                  // margin: '0 16px',
                  // marginRight: 'auto',
                }}
              >
                {manual[1]}
              </label>
              {/* <img src={images[index]} /> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}
