import React, { useHistory } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import api from '../../services/api'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Container, Text, Preview, Img, ContainerName } from './style'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const Button = ({ children, imageModule, percent, ...rest }) => {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)')

  return (
    <Container {...rest}>
      <ContainerName>
        <Preview>
          {imageModule ? (
            <Img src={`${api.defaults.baseURL}${imageModule}`} />
          ) : null}
        </Preview>
        <Text>{children}</Text>
      </ContainerName>

      <CircularProgressWithLabel
        value={isNaN(percent) ? 0 : percent}
        size={matches ? '4rem' : '2rem'}
      />
    </Container>
  )
}

export default Button
