import React, { useEffect, useState } from 'react'
import RegisterUser from '../../Layouts/User/RegisterUsers'
import ListUser from '../../Layouts/User/ListUsers'

export default function User() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')

  const handleEditUser = (data) => {
    setEdit(true)
    setName(data.name)
    setEmail(data.email)
    setId(data.id)
  }

  return (
    <>
      <RegisterUser
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        edit={edit}
        setEdit={setEdit}
        id={id}
        setId={setId}
      />
      <ListUser handleEditUser={handleEditUser} />
    </>
  )
}
