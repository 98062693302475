import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  /* height: 7rem; */
  padding: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const Text = styled.h2`
  width: 100%;
  margin-bottom: 0;
  font-size: clamp(1rem, 3vw, 2.25rem);
  padding: 16px;
  font-weight: bold;
  font-family: Montserrat;
  color: #263b4c;
`
export const Preview = styled.div`
  width: 5.4rem;
  height: 5.4rem;
  margin-bottom: 1px;
  @media (max-width: 575px) {
    width: 2.9rem;
    height: 2.9rem;
  }
`
export const ContainerName = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
`
export const Img = styled.img`
  width: 5.4rem;
  height: 5.4rem;
  margin-bottom: 1px;
  @media (max-width: 575px) {
    width: 2.9rem;
    height: 2.9rem;
  }
`
