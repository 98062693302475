import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'

import FormPaginate from '../../../Components/FormPaginate'
import FormManual from '../../Layouts/Questions/EditManual'

export default function EditQuestion() {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [questions, setQuestions] = useState([])
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState(2)
  const [manual, setManual] = useState([])

  useEffect(() => {
    handleChangeData()
  }, [])

  const handleChangeData = async () => {
    try {
      setLoading(true)
      const manualData = JSON.parse(localStorage.getItem('@BV3/dataManual'))
      setManual(manualData)

      const response = await api.get(`/responses/${manualData.id}`)

      setData(response.data)
      setLastPage(Math.ceil(response.data.length / 4))

      let numPage = page - 1
      let start = numPage * 4
      let end = start + 4

      let newDatas = response.data.slice(start, end)
      setQuestions(newDatas)

      setLoading(false)
    } catch (response) {
      setLoading(false)
      alertError(response.data.error)
    }
  }

  const handleNewPage = async (newPage) => {
    try {
      setLoading(true)
      setPage(newPage)
      let numPage = newPage - 1
      let start = numPage * 4
      let end = start + 4

      let newDatas = data.slice(start, end)

      setQuestions(newDatas)
      setLoading(false)
    } catch (response) {
      setLoading(false)
      alertError(response.data.error)
    }
  }

  const handleSubmit = async (e) => {
    try {
      setDisable(true)
      setLoading(true)

      var dataResponse = new FormData()

      data.map((response, index) => {
        if (response.image !== '' && response.image.preview) {
          dataResponse.append('files', response.image.file, response.image.name)
        }
      })

      dataResponse.append('data', JSON.stringify(data))

      const response = await api.post(`/responses/${manual.id}`, dataResponse)

      alertSuccess(response.data.success)
      setLoading(false)
      setDisable(false)
      history.replace('/dashboard/manuals')
    } catch (response) {
      setLoading(false)
      setDisable(false)
      alertError(response.data.error)
    }
  }

  return (
    <div className="form-manual-dashboard">
      {loading == true && (
        <div className="loading-dashboard">
          <CircularProgress />
        </div>
      )}
      <FormPaginate
        title={manual.title}
        titleButton={'Atualizar'}
        disable={disable}
        page={page}
        handleNewPage={handleNewPage}
        lastPage={lastPage}
        handleSubmit={handleSubmit}
      >
        <FormManual
          questions={questions}
          setQuestions={setQuestions}
          setData={setData}
          data={data}
          page={page}
        />
      </FormPaginate>
    </div>
  )
}
