import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import $ from 'jquery'

import IuPassword from '../../../../Components/InputPassword'

import { alertError, alertInfo } from '../../../../utils/Alert'
import api from '../../../../services/api'

export default function Reset() {
  const history = useHistory()
  const { token } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [photo, setPhoto] = useState(0)

  useEffect(() => {
    setPhoto(Math.floor(Math.random() * (3 - 1) + 1))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (password === '' || confirmPassword === '') {
        alertInfo('Por favor preencha todos os campos!')
        return
      }

      if (password !== confirmPassword) {
        alertInfo('Confirmação de senha incorreta!')
        return
      }

      const response = await api.post('/user/reset-password', {
        token,
        password,
        confirmPassword,
      })

      if (response.data.success) {
        $('.form-items', '.form-content').addClass('hide-it')
        $('.form-sent', '.form-content').addClass('show-it')

        setTimeout(function () {
          history.push('/login')
        }, 5000)
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }

  return (
    <div className="form-body">
      <div className="auth-logo">
        <img
          className="logo-manual"
          src="../assets/images/logo-dark.png"
          alt=""
        />
        <h1>
          Construindo em conjunto com <strong>você</strong> os melhores{' '}
          <strong>resultados!</strong>
        </h1>
        <a href="">
          <div className="logo">
            {photo == 1 ? (
              <img
                className="logo-size"
                src="../assets/images/creator-amico.svg"
                alt=""
              />
            ) : (
              photo == 2 && (
                <img
                  className="logo-size"
                  src="../assets/images/instruction-manual.jpg"
                  alt=""
                />
              )
            )}
          </div>
        </a>
      </div>
      <div className="row">
        <div className="img-holder">
          <div className="bg"></div>
          <div className="info-holder"></div>
        </div>
        <div className="form-holder">
          <div className="form-content">
            <div className="form-items">
              <h3>Resetar Senha</h3>
              <p>Digite a nova senha desejada, e depois confirme-a.</p>
              <form>
                <IuPassword
                  value={password}
                  setValue={setPassword}
                  placeHolder="Senha:"
                />
                <IuPassword
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  placeHolder="Confirmar senha:"
                />
                <div className="form-button ">
                  <button
                    id="submit"
                    className="ibtn btn-forget"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Confirmar
                  </button>
                </div>
              </form>
            </div>
            <div className="form-sent">
              <div className="tick-holder">
                <div className="tick-icon"></div>
              </div>
              <h3>Senha Alterada</h3>
              <p>Redirecionando para o login...</p>
              <div className="info-holder"></div>
            </div>
            <a href="">
              <div className="website-logo logo">
                <p>by:</p>
                <img
                  className="logo-size"
                  src="../assets/images/logo2-white.png"
                  alt=""
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
