import React from 'react'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

export default function Header({ title }) {
  const history = useHistory()

  const handleConfirm = () => {
    Swal.fire({
      title: 'Ter certeza que deseja voltar ao perfil sem salvar?',
      text:
        'Se voltar ao perfil o manual não será salvo, para salvar e responder mais tarde clique no botão salvar no canto inferior dessa página!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, voltar',
      cancelButtonText: 'cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        history.replace('/home')
      }
    })
  }

  return (
    <div className="header-question-page">
      <a onClick={() => handleConfirm()}>Perfil</a>
    </div>
  )
}
