import React, { useState } from 'react'
import Upgrade from '../../Layouts/Upgrade'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'
import { getUser } from '../../../services/auth'

export default function PageUpgrade() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState({
    website: false,
    cover: false,
    banner: false,
    pratics: false,
    praticsNarration: false,
    properties: false,
    maintenance: false,
    assistance: false,
    discount: false,
  })
  const values = {
    website: 500,
    cover: 250,
    banner: 150,
    pratics: 800,
    praticsNarration: 999.9,
    properties: 80,
    maintenance: 80,
    assistance: 80,
    discount: 0,
  }

  const [total, setTotal] = useState(0)

  const handleSelectServices = (service) => {
    let newServices

    if (services[service] == false) {
      newServices = {
        ...services,
        [service]: true,
      }

      setServices(newServices)

      setTotal(total + values[service])
    } else {
      newServices = {
        ...services,
        [service]: false,
      }
      setServices(newServices)
      setTotal(total - values[service])
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let countFalse = 0
      let totalServices = 0
      for (var [key, value] of Object.entries(services)) {
        totalServices++
        if (value == false) {
          countFalse++
        }
      }

      if (countFalse == totalServices) {
        setLoading(false)
        return alertInfo(
          'Selecione algum serviço ou clique em "continuar sem um upgrade". '
        )
      }

      const { email } = getUser()

      if (
        services.properties == true ||
        services.maintenance == true ||
        services.assistance == true ||
        services.discount == true
      )
        await api.post('/upgrade', { services, email })

      if (
        services.cover == true ||
        services.banner == true ||
        services.pratics == true ||
        services.praticsNarration == true ||
        services.website == true
      )
        await api.post('/upgrade/mind', { services, email })

      setLoading(false)
      alertSuccess('Upgrade enviado com sucesso')
      history.replace('/home')
    } catch (response) {
      setLoading(false)
      alertError(response.data.error)
    }
  }

  return (
    <>
      {loading == true && (
        <div className="loading-page-full">
          <CircularProgress />
        </div>
      )}
      <Upgrade
        services={services}
        total={total}
        handleSelectServices={handleSelectServices}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
