import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import IuPassword from '../../../../Components/InputPassword'

import { alertError, alertInfo, alertSuccess } from '../../../../utils/Alert'
import api from '../../../../services/api'
import { login } from '../../../../services/auth'

export default function Login() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [photo, setPhoto] = useState(0)

  useEffect(() => {
    setPhoto(Math.floor(Math.random() * (3 - 1) + 1))
  }, [])

  const handlesubmit = async (e) => {
    e.preventDefault()
    try {
      if (email === '' || password === '') {
        alertInfo('Por favor preencha todos os campos!')
        return
      }

      const response = await api.post('/user/login', {
        email,
        password,
      })

      login(response.data.token, response.data.user, '1')

      history.push('/home')

      setEmail('')
      setPassword('')
    } catch (response) {
      alertError(response.data.error)
    }
  }

  return (
    <div className="form-body">
      <div className="auth-logo">
        <img
          className="logo-manual"
          src="../assets/images/logo-dark.png"
          alt=""
        />
        <h1>
          Construindo em conjunto com <strong>você</strong> os melhores{' '}
          <strong>resultados!</strong>
        </h1>
        <a href="">
          <div className="logo">
            {photo == 1 ? (
              <img
                className="logo-size"
                src="../assets/images/creator-amico.svg"
                alt=""
              />
            ) : (
              photo == 2 && (
                <img
                  className="logo-size"
                  src="../assets/images/instruction-manual.jpg"
                  alt=""
                />
              )
            )}
          </div>
        </a>
      </div>
      <div className="row">
        <div className="img-holder">
          <div className="bg"></div>
          <div className="info-holder"></div>
        </div>
        <div className="form-holder">
          <div className="form-content">
            <div className="form-items">
              <h3>
                Através da BV3 você tem acesso ao suporte e a assistência certa!
              </h3>
              <p>
                A BV3 manuais te fornece de forma prática o manual ideal para a
                sua necessidade!
              </p>
              <div className="page-links">
                <a className="active">Login</a>
                <a onClick={() => history.push('/cadastro')}>Cadastro</a>
              </div>
              <form>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="E-mail: "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <IuPassword
                  value={password}
                  setValue={setPassword}
                  placeHolder="Senha:"
                />

                <div className="form-button">
                  <button
                    id="submit"
                    className="ibtn"
                    onClick={(e) => handlesubmit(e)}
                  >
                    Login
                  </button>{' '}
                  <a onClick={() => history.push('/forgot')}>
                    Esqueceu sua senha?
                  </a>
                </div>
              </form>
              <a href="">
                <div className="website-logo logo">
                  <p>by:</p>
                  <img
                    className="logo-size"
                    src="../assets/images/logo2-white.png"
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
