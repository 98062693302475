import React, { useEffect, useState, createRef } from 'react'
import MaterialTable from 'material-table'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { tableIcons } from '../../../utils/TableIcons'
import { alertSuccess, alertError } from '../../../utils/Alert'

import api from '../../../services/api'

export default function ListCategory({ handleEditCategory }) {
  const tableRef = createRef()
  const history = useHistory()

  const handleEdit = async (e, data, tableRef) => {
    localStorage.setItem('@BV3/idCategorySelected', data.id)
    history.push('/dashboard/category/edit')
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="div-table">
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: 'Categoria de Manuais',
              field: 'categorie',
              type: 'string',
              filtering: false,
            },
            {
              title: 'Data de cadastro',
              field: 'created_at',
              type: 'datetime',
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let field = ''
              if (query.orderBy !== undefined) field = query.orderBy.field
              api
                .get(
                  `/categories?per_page=${query.pageSize}&page=${
                    query.page + 1
                  }&search=${query.search}&columnOrder=${field}&order=${
                    query.orderDirection
                  }`
                )
                .then((result) => {
                  resolve({
                    data: result.data.categories,
                    page: query.page,
                    totalCount: result.data.total,
                  })
                })
            })
          }
          title="Listagem de Categorias de Manuais"
          icons={tableIcons}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: 'Editar Categoria de manual',
              onClick: (event, rowData) => {
                history.push(`/dashboard/editCategorie/${rowData.id}`)
              },
            },
            {
              icon: tableIcons.RefreshIcon,
              tooltip: 'Atualizar Tabela',
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
          options={{
            pageSize: 20,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página seguinte',
              lastTooltip: 'Última página',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhuma categoria encontrada',
            },
          }}
        />
      </div>
    </div>
  )
}
