import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chart from 'react-apexcharts'

import { usePercentage } from '../../hooks/usePercentage'

export default function MainHeader({ title, hasChart = false }) {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  const { totalQuest, totalResp } = usePercentage()

  const options = {
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },
    },
    labels: ['Respondido', 'Não respondido'],
    legend: {
      fontSize: '20px',
      fontFamily: 'Helvetica, Arial',
      labels: { colors: 'white' },
    },
    colors: ['#004085', '#546E7A'],
  }

  let percentage
  let series

  if (totalQuest > 0) percentage = (totalResp * 100) / totalQuest
  else percentage = 0

  series = [percentage.toFixed(2) / 100, (100 - percentage.toFixed(2)) / 100]

  const handleConfirm = () => {
    Swal.fire({
      title: 'Ter certeza que deseja voltar ao perfil sem salvar?',
      text:
        'Se voltar ao perfil o manual não será salvo, para salvar e responder mais tarde clique no botão salvar no canto inferior dessa página!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, voltar',
      cancelButtonText: 'cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        history.replace('/home')
      }
    })
  }

  return (
    <div className="header-question-page main" backgroundColor="#263b4c">
      <a onClick={() => handleConfirm()}> Perfil</a>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <h1
          style={{
            maxWidth: '100%',
            marginTop: '0.5rem',
            paddingInline: '2rem',
            fontSize: 'clamp(1.125rem, 3.5vw, 2.75rem)',
            textAlign: 'center',
          }}
        >
          {title}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingInline: '1rem',
            paddingTop: 16,
          }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && hasChart && (
            <Chart options={options} series={series} type="pie" width="340px" />
          )}
        </div>
      </div>
    </div>
  )
}
