import React, { useState } from 'react'

import QRCode from 'qrcode.react'

import useStyles from './style'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import { url } from '../../services/api'

export default function ModalQRCode({ open, setOpen, url: codeUrl }) {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <a onClick={() => handleClose()} className={classes.close}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
            <QRCode value={'http://manual.bv3engenharia.com.br/' + codeUrl} className={classes.qrcode} />
            <a href={codeUrl} target="_blank" className="modal-link-manual">
              {' '}
              Abrir manual{' '}
            </a>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
