import { createContext, useState, useEffect, useContext } from 'react'

const PercentageContext = createContext({})

export const PercentageProvider = ({ children }) => {
  const [totalQuest, setTotalQuest] = useState(0)
  const [totalResp, setTotalResp] = useState(0)

  return (
    <PercentageContext.Provider
      value={{
        totalQuest,
        setTotalQuest,
        totalResp,
        setTotalResp,
      }}
    >
      {children}
    </PercentageContext.Provider>
  )
}

export const usePercentage = () => {
  const context = useContext(PercentageContext)
  return context
}
