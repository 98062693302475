import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  divIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonIcon: {
    marginBottom: -30,
  },
  icon: {
    color: 'darkred',
  },
  text: {
    marginBottom: 15,
  },
  buttonAddInput: {
    backgroundColor: 'green',
    color: 'white',
  },
}))

export default useStyles
