import axios from 'axios'
import { getToken } from './auth'

export const url = 'http://162.214.54.8:3411/'

const api = axios.create({
  //DEV
  // baseURL: 'http://162.214.160.241:3308',
  //DEV - local
  // baseURL: 'http://localhost:3308',
  // baseURL: 'http://192.168.0.17:3308',
  //TEST
  // baseURL: 'http://162.214.160.241:3312',
  //TEST - local
  //baseURL: 'http://192.168.0.11:3312',
  // baseURL: 'http://162.214.160.241:3312/',
  baseURL: 'http://162.214.54.8:3411/',
  // baseURL: 'http://localhost:3312',
})

api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use((response) => {
  if (response.data.error) {
    throw response
  } else {
    return response
  }
})

export default api
