import React, { useEffect } from 'react'

import { Container, FileInfo, Preview } from './style'
import { url } from '../../services/api'

export default function FileList({ files, handleDeleteUpload, index }) {
  if (Array.isArray(files)) {
    return (
      <Container>
        {files.map((file) => {
          if (file.length <= 0) return null
          return (
            <li>
              <FileInfo>
                {file.preview ? (
                  <Preview src={file.preview} href={file.preview} />
                ) : (
                  <Preview
                    // src={'http://localhost:3411/' + files}
                    // href={'http://localhost:3411/' + files}
                    src={url + file}
                    href={url + file}
                  />
                )}
                <div>
                  {file.preview ? (
                    <strong>{file.name}</strong>
                  ) : (
                    <strong>
                      {''.concat([file.slice(file.indexOf('-') + 1)])}
                    </strong>
                  )}
                  <span>
                    {file.preview ? (
                      <a href={file.preview} target="_blank">
                        Visualizar
                      </a>
                    ) : (
                      <a
                        // href={'http://localhost :3411/' + file}
                        href={url + file}
                        target="_blank"
                      >
                        Visualizar
                      </a>
                    )}
                    {/* <button onClick={() => handleDeleteUpload(index)}>
                      Excluir
                    </button> */}
                  </span>
                </div>
              </FileInfo>
            </li>
          )
        })}
      </Container>
    )
  } else {
    return (
      <li>
        <FileInfo>
          {files.preview ? (
            <Preview src={files.preview} href={files.preview} />
          ) : (
            <Preview
              // src={'http://localhost:3411/' + files}
              // href={'http://localhost:3411/' + files}
              src={url + files}
              href={url + files}
            />
          )}
          <div>
            {files.preview ? (
              <strong>{files.name}</strong>
            ) : (
              <strong>
                {''.concat([files.slice(files.indexOf('-') + 1)])}
              </strong>
            )}
            <span>
              {files.preview ? (
                <a href={files.preview} target="_blank">
                  Visualizar
                </a>
              ) : (
                // <a href={'http://localhost:3411/' + files} target="_blank">
                <a href={url + files} target="_blank">
                  Visualizar
                </a>
              )}
              {/* <button onClick={() => handleDeleteUpload(index)}>
                Excluir
              </button> */}
            </span>
          </div>
        </FileInfo>
      </li>
    )
  }
}
