import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from '../services/auth'

import AccessRestricted from '../Views/Pages/PagesError/AccessRestricted'

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && localStorage.getItem('@BV3/access') === '999' ? (
        <Component {...props} />
      ) : isAuthenticated() && localStorage.getItem('@BV3/access') !== '999' ? (
        <>
          <AccessRestricted />
        </>
      ) : (
        <Redirect to={{ pathname: '/admin/login' }} />
      )
    }
  />
)

export const UserRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && localStorage.getItem('@BV3/access') === '1' ? (
        <Component {...props} />
      ) : isAuthenticated() && localStorage.getItem('@BV3/access') !== '1' ? (
        <>
          <AccessRestricted />
        </>
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
)
