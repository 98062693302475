import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import Swal from 'sweetalert2'

import Header from '../../../Components/HeaderQuestion/Header'
import MainHeader from '../../../Components/HeaderQuestion/MainHeader'
import ButtonModule from '../../../Components/ButtonModule'
import TitleManual from '../../../Components/TitleManual'
import api from '../../../services/api'
import { getUser } from '../../../services/auth'
import { nl2br } from '../../../utils/breakLine'

import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import FirstPage from './FirstPage'
import {
  LocalConvenienceStoreOutlined,
  PhotoSizeSelectLargeTwoTone,
} from '@material-ui/icons'
import { queryByTestId } from '@testing-library/dom'
import Pagination from '../../../Components/Pagination'
import { usePercentage } from '../../../hooks/usePercentage'

const fetcher = (...params) => api.get(...params)

export default function Form({
  answers,
  setAnswers,
  title,
  setTitle,
  handleSubmit,
  disable,
  setDisable,
  cover,
  setCover,
  manual,
  handleSubmitNext,
  handleChangeAnswers,
}) {
  // console.log('ans', answers)
  const myRefname = useRef()
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [lastPage, setLastPage] = useState(2)
  const [total, setTotal] = useState(0)
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [chosenPage, setChosenPage] = useState(1)
  const [listModules, setListModules] = useState([])
  const [novoArray, setNovoArray] = useState([])
  const [countResp, setcountResp] = useState([])
  const [auxRes, setAuxRes] = useState([])
  const [module, setModule] = useState()
  const [data, setData] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [loadingModules, setLoadingModules] = useState(false)
  const [manualPercent, setManualPercent] = useState({})
  const [control, setControl] = useState(0)
  const [files, setFiles] = useState([])
  const [pagePagination, setPagePagination] = useState(1)
  const [totalModulos, setTotalModulos] = useState(0)
  // const {  error } = useSWR('/count/questions', fetcher)

  const { totalQuest, totalResp, setTotalQuest, setTotalResp } = usePercentage()

  useEffect(() => {
    const getManualCompletion = async () => {
      let manualData = JSON.parse(localStorage.getItem('@BV3/dataManual'))

      try {
        if (!!manualData) {
          const { id, categorie_id } = manualData

          const response = await api.get(
            `/manual/completion/${id}/${categorie_id}`
          )

          const { numeroPerguntas, numeroRespostas } = response.data

          if (!!numeroPerguntas && !!numeroRespostas) {
            setTotalQuest(numeroPerguntas)
            setTotalResp(numeroRespostas)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    getManualCompletion()
  }, [])

  useEffect(() => {
    let manualData = JSON.parse(localStorage.getItem('@BV3/dataManual'))

    if (manualData && manual.title != '') {
      handlePage(parseInt(manual.page), 0, true)
      setIsEdit(true)
      setPage(1)
      loadModules(true)
    } else {
      loadModules(false)
    }
  }, [])

  useEffect(() => {
    const verify = async () => {
      try {
        let aux = localStorage.getItem('@BV3/dataManual')
        aux = JSON.parse(aux)
        const response = await api.get(
          `/user/questions?page=1&per_page=&module_id=${module}&manual_id=${
            aux ? aux.id : 'null'
          }`
        )
        // console.log('resp api', response)
        let data = response.data.questions
        for (let i = 0; i < data.length; i++) {
          if (data[i].alternatives) {
            data[i].alternatives = data[i].alternatives.filter(
              (alternative, index, self) =>
                index === self.findIndex((t) => t.id === alternative.id)
            )
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].alternatives) {
            data[i].alternatives_responses = data[
              i
            ].alternatives_responses.filter(
              (alternative, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.alternative_id === alternative.alternative_id
                )
            )
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].alternatives) {
            data[i].alternatives.forEach((alternative) => {
              if (
                data[i].alternatives_responses.some(
                  (e) => e.alternative_id == alternative.id
                )
              ) {
                alternative.checked = true
              }
            })
          }
        }
        let arrayNoResponses = []
        if (isEdit) {
          if (data != null) {
            data?.map((item, index) => {
              // console.log('item', item)
              // console.log(item)
              if (
                (item.response === null &&
                  (item.alternatives_responses === null ||
                    item.alternatives_responses[0]?.alternative_id === null) &&
                  item.not_applicable != 1 &&
                  item.images === null) ||
                item.images[0].image === null
              ) {
                arrayNoResponses.push(index + 1)
              }
            })
          }
          if (arrayNoResponses.length > 0) {
            await Swal.fire({
              title: `Questão(ões) não respondida(s):`,
              text: ` ${arrayNoResponses.join(', ')} `,
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#000',
              confirmButtonText: 'OK',
            })
          }
        }
      } catch (error) {}
    }
    if (page === 2) {
      verify()
    }
    // else if (page === 1) {
    //   handleChangeAnswers()
    //   console.log('chamou api')
    // }
  }, [page, control])

  useEffect(() => {
    loadModules(true)
  }, [pagePagination])

  // useEffect(() => {
  //   if (data) {
  //     setLastPage(Math.ceil(data.data.total / 3) + 1)
  //     console.log(data)
  //     setTotal(data.data.total)
  //   }
  // }, [data])

  // useEffect(() => {
  //   if (localStorage.getItem('@BV3/idModules')) {
  //     handleNewPage(1)
  //   }
  // }, [])]

  // const countRes = async () => {
  //   let module_id = localStorage.getItem('@BV3/novoArray')
  //   let manual = JSON.parse(aux)
  //   manual_id = aux.id
  //   console.log('ids', manual.id)

  //   console.log('res quest', responseQuest)
  //   console.log('res res', responseRes)
  // }

  const loadModules = async (isEdit = false, index) => {
    setLoadingModules(true)
    const categorieIdStorage = localStorage.getItem('@BV3/idCategorie')
    setLoading(true)
    if (categorieIdStorage) {
      let response = await api.get('/modules', {
        params: {
          per_page: 5,
          page: pagePagination,
          columnOrder: '',
          order: '',
          search: '',
          filters: JSON.stringify([categorieIdStorage]),
        },
      })
      console.log(response)
      setTotalModulos(response.data.total)
      let aux = []
      let manual_aux = JSON.parse(localStorage.getItem('@BV3/dataManual'))

      let novoArray = []

      for await (let objeto of response.data.modules) {
        const { id, module, image_module } = objeto
        aux.push(id)
        let responseRes
        let responseQuest
        if (isEdit && !!manual_aux) {
          responseQuest = await api.get(`/count/questions/${id}`)
          responseRes = await api.get(`/contRes/${id}/${manual_aux.id}`)
        } else {
          responseQuest = 0
          responseRes = 0
        }
        novoArray.push({
          id,
          module,
          image_module,
          totalQuest: responseQuest?.data?.total,
          numberResponse: responseRes?.data?.total,
        })
      }

      setNovoArray(novoArray)

      let totalResp = 0
      let totalQuest = 0

      novoArray.forEach((module) => {
        totalResp += module.numberResponse
        totalQuest += module.totalQuest
      })
      setManualPercent({ totalResp, totalQuest })

      localStorage.setItem('totalResp', totalResp)
      localStorage.setItem('totalQuest', totalQuest)
      // JSON.parse(novoArray)

      // console.log('lista de modulos', aux)
      // localStorage.setItem('@BV3/novoArray', aux)
      // setAuxRes(localStorage.getItem('@BV3/novoArray'))

      listModules[index] = response.data.modules
      setListModules([...listModules])
    }
    setLoading(false)
    setLoadingModules(false)
  }
  const handleModule = (moduleId) => {
    // setModuleId(moduleId)
    // console.log('chegou')
    handleNewPage(page + 1, moduleId)
    setModule(moduleId)
  }

  const handleNewPage = async (newPage, moduleId = 0) => {
    let verifyQuestion = -1
    let questionNull = []
    let totalAlternatives = 0
    let checkedFalse = 0
    let eReturn = false

    if (page > 0) verifyQuestion = (page - 1) * 3

    if (verifyQuestion === -1) {
      if (title === '' && cover === '') {
        await Swal.fire({
          title: `Questão(ões) não respondida(s):`,
          text: 'Capa e Título do manual',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#000',
          confirmButtonText: 'OK',
          cancelButtonText: 'Prosseguir',
        }).then((result) => {
          if (result.isDismissed) {
            handlePage(newPage, moduleId)
          } else {
            eReturn = true
          }
        })
      } else if (cover === '') {
        await Swal.fire({
          title: `Questão(ões) não respondida(s):`,
          text: 'Capa do manual',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#000',
          confirmButtonText: 'OK',
          cancelButtonText: 'Prosseguir',
        }).then((result) => {
          if (result.isDismissed) {
            handlePage(newPage, moduleId)
          } else {
            eReturn = true
          }
        })
      } else if (title === '') {
        await Swal.fire({
          title: `Questão(ões) não respondida(s):`,
          text: 'Título do manual',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#000',
          confirmButtonText: 'OK',
          cancelButtonText: 'Prosseguir',
        }).then((result) => {
          if (result.isDismissed) {
            handlePage(newPage, moduleId)
          } else {
            eReturn = true
          }
        })
      }
    } else if (verifyQuestion > 0) {
      verifyQuestion--
      for (
        var i = verifyQuestion - 2;
        i < verifyQuestion - 2 + questions.length;
        i++
      ) {
        if (answers[i]) {
          if (answers[i]?.notApplicable === false) {
            if (answers[i]?.image === null) {
              if (answers[i]?.answer === '') {
                if (answers[i]?.alternatives.length > 0) {
                  for (var z = 0; z < answers[i].alternatives.length; z++) {
                    if (
                      answers[i].alternatives[z] != undefined &&
                      answers[i].alternatives[z] != null
                    ) {
                      totalAlternatives++
                    }
                    if (
                      answers[i]?.alternatives[z] != undefined &&
                      answers[i]?.alternatives[z] != null &&
                      answers[i]?.alternatives[z].checked == false
                    ) {
                      checkedFalse++
                    }
                  }
                  if (checkedFalse == totalAlternatives) {
                    questionNull.push(i + 1)
                  }
                } else {
                  questionNull.push(i + 1)
                }
              }
            }
          }
        } else {
          questionNull.push(i + 1)
        }
      }
    }

    if (questionNull.length > 0) {
      await Swal.fire({
        title: `Salve as questões para não perder sua progressão!
        Questão(ões) não respondida(s):
        `,
        text: ` ${questionNull.join(', ')} `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#000',
        confirmButtonText: 'OK',
        cancelButtonText: 'Prosseguir',
      }).then((result, e) => {
        if (result.isDismissed) {
          setLoading(false)
          handlePage(newPage, moduleId)
        } else {
          setLoading(false)
          eReturn = true
        }
      })
    }

    if (eReturn === true) return
    else handlePage(newPage, moduleId)
  }

  const deleteFile = async (position, index) => {
    let arrayFiles = files
    const imageUrl = arrayFiles[position][index].url
    arrayFiles[position][index] = null
    setFiles(arrayFiles)
    answers[position] = {
      ...answers[position],

      image: arrayFiles[position],
    }
    setAnswers([...answers])
    if (imageUrl) await api.post('/delete-img', { imageUrl: imageUrl })
  }
  const addFiles = async (fileList, index, question, imageIndex = 1) => {
    let flag = 0
    let filesArray = files

    // console.log('flag', flag)

    if (files[index] && imageIndex > 0)
      filesArray[index] = [...files[index], ...fileList]

    if (!files[index] || imageIndex == 0)
      filesArray[index] = [...[], ...fileList]
    // console.log('files a', filesArray)
    setFiles(filesArray)
    answers[index] = {
      ...answers[index],
      indexArray: index,
      question,
      answer: '',
      image: filesArray[index],
      note: '',
      alternatives: [],
      notApplicable: false,
    }
    // console.log('teste', answers[index])

    setAnswers([...answers])
  }

  const handlePage = async (newPage, moduleId = 0, fixed = false) => {
    if (moduleId === 0) {
      moduleId = module
    }
    setLoading(true)
    if (newPage === 0 || newPage === 1) {
      setPage(newPage)
      setLoading(false)
      return
    }

    setPage(newPage)

    try {
      if (newPage === 2) {
        const data = await api.get(`/count/questions/${moduleId}`)

        setData(data)
        setLastPage(Math.ceil(data.data.total / 3) + 2)

        setTotal(data.data.total)
      }
      let aux = localStorage.getItem('@BV3/dataManual')
      aux = JSON.parse(aux)

      const pag = `/user/questions?page=${
        newPage - 1
      }&per_page=${3}&module_id=${moduleId}&manual_id=${aux ? aux.id : 'null'}`

      // console.log(pag)
      // console.log('aux manual', aux)

      const response = await api.get(pag)
      let data = response.data.questions
      for (let i = 0; i < data.length; i++) {
        if (data[i].alternatives) {
          data[i].alternatives = data[i].alternatives.filter(
            (alternative, index, self) =>
              index === self.findIndex((t) => t.id === alternative.id)
          )
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].alternatives) {
          data[i].alternatives_responses = data[
            i
          ].alternatives_responses.filter(
            (alternative, index, self) =>
              index ===
              self.findIndex(
                (t) => t.alternative_id === alternative.alternative_id
              )
          )
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].alternatives) {
          data[i].alternatives.forEach((alternative) => {
            if (
              data[i].alternatives_responses.some(
                (e) => e.alternative_id == alternative.id
              )
            ) {
              alternative.checked = true
            }
          })
        }
      }

      setQuestions(data)

      // simulando respostas
      setAnswers([])
      setFiles([])
      data.forEach(async function (value, index) {
        // console.log('value', value)
        // console.log('index', index + 3 * (newPage - 2))
        if (value.not_applicable) {
          handleNotApplicable(
            index + 3 * (newPage - 2),
            value.question_id,
            value.not_applicable
          )
        }
        if (value.commentary) {
          handleNoteData(
            index + 3 * (newPage - 2),
            value.question_id,
            value.commentary
          )
        }
        if (value.response) {
          handleAnswerData(
            index + 3 * (newPage - 2),
            value.question_id,
            value.response
          )
        }

        if (value.alternatives_responses && value.type_id === 1) {
          value.alternatives_responses.forEach(async function (v, a) {
            if (v.alternative_id)
              handleAlternativeData(
                index + 3 * (newPage - 2),
                v.index_alternative,
                value.question_id,
                v.alternative_id,
                a == 0 ? true : false
              )
          })
        }
        // console.log('vv', value)
        if (value.alternatives_responses && value.type_id === 2) {
          value.alternatives_responses.forEach(async function (t, b) {
            if (t.alternative_id)
              handleAlternativesData(
                index + 3 * (newPage - 2),
                t.index_alternative,
                value.question_id,
                t.alternative_id,
                b == 0 ? true : false
              )
          })
        }
        if (value.images && value.type_id === 4) {
          // console.log('value', value)
          value.images.forEach(async function (g, s) {
            if (g.image) {
              addFiles(
                [{ url: g.image }],
                index + 3 * (newPage - 2),
                value.question_id,
                s
              )
            }
          })
        }
      })
      // console.log('data', data)
      if (!fixed) setLoading(false)
    } catch (response) {
      if (!fixed) setLoading(false)
    }
  }

  const formatNomeModule = (novoArrayFiltrado) => {
    let texto = ''
    let tamanhoArray = novoArrayFiltrado.length

    if (tamanhoArray === 1) texto += novoArrayFiltrado[0].module
    else {
      novoArrayFiltrado.forEach((module, index) => {
        if (index == 0) {
          texto += ` ${module.module}`
        } else if (index + 1 === tamanhoArray) {
          texto += ` e ${module.module}`
        } else {
          texto += `, ${module.module}`
        }
      })
    }
    console.log('tx', texto)
    return texto
  }

  const confirmSubmit = () => {
    const array = returnModuleNotResponses()
    Swal.fire({
      title: 'Tem certeza que deseja finalizar o manual?',
      text: `O(s) módulo(s) ${formatNomeModule(
        returnModuleNotResponses()
      )} apresenta(m) pergunta(s) sem resposta, deseja continuar?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleCompletion()
      }
    })
  }

  const handleCompletion = async (e) => {
    try {
      setDisable(true)

      let countNull = 0
      let totalAlternatives = 0
      let checkedFalse = 0
      let eReturn = false

      if (cover === '' || title === '') {
        alertInfo(
          'Por favor, responda o título e escolha a capa para finalizar o manual!'
        )
        setLoading(false)
        setDisable(false)

        return
      }

      // for (var i = 0; i < answers.length; i++) {
      //   totalAlternatives = 0
      //   checkedFalse = 0

      //   if (answers[i]?.notApplicable === false) {
      //     if (answers[i]?.answer === '') {
      //       if (answers[i]?.alternatives.length > 0) {
      //         for (var z = 0; z < answers[i]?.alternatives.length; z++) {
      //           if (
      //             answers[i]?.alternatives[z] != undefined &&
      //             answers[i]?.alternatives[z] != null
      //           ) {
      //             totalAlternatives++
      //           }
      //           if (
      //             answers[i]?.alternatives[z] != undefined &&
      //             answers[i]?.alternatives[z] != null &&
      //             answers[i]?.alternatives[z].checked === false
      //           ) {
      //             checkedFalse++
      //           }
      //         }
      //         if (checkedFalse == totalAlternatives) {
      //           await Swal.fire({
      //             title: `Ainda tem questões não respondidas!`,
      //             text: 'Deseja finalizar o manual mesmo assim?',
      //             icon: 'info',
      //             showCancelButton: true,
      //             confirmButtonColor: '#3085d6',
      //             cancelButtonColor: '#d33',
      //             confirmButtonText: 'Finalizar',
      //             cancelButtonText: 'Cancelar',
      //           }).then((result) => {
      //             if (result.isDismissed) {
      //               eReturn = true
      //               setDisable(false)
      //               //setLoading(false)
      //               return
      //             }
      //           })
      //         }
      //       } else {
      //         await Swal.fire({
      //           title: `Ainda tem questões não respondidas!`,
      //           text: 'Deseja finalizar o manual mesmo assim?',
      //           icon: 'info',
      //           showCancelButton: true,
      //           confirmButtonColor: '#3085d6',
      //           cancelButtonColor: '#d33',
      //           confirmButtonText: 'Finalizar',
      //           cancelButtonText: 'Cancelar',
      //         }).then((result) => {
      //           if (result.isDismissed) {
      //             eReturn = true
      //             setDisable(false)
      //             //setLoading(false)
      //             return
      //           }
      //         })
      //       }
      //     }
      //   }
      // }

      let manual
      if (localStorage.getItem('@BV3/dataManual') !== null)
        manual = JSON.parse(localStorage.getItem('@BV3/dataManual'))

      let userData = getUser()
      let { id } = userData

      var newArray = []

      for (var i = 0; i < answers.length; i++) {
        if (answers[i] !== undefined) {
          newArray.push(answers[i])
        }
      }

      let dataManual = {}

      let image = new FormData()

      if (cover.preview) {
        dataManual = {
          user_id: id,
          title,
          answers: newArray,
          is_finished: true,
          page,
        }

        image.append('file', cover.file, cover.name)
      } else {
        dataManual = {
          user_id: id,
          title,
          answers: newArray,
          is_finished: true,
          cover,
          page,
        }
      }

      let response

      if (localStorage.getItem('@BV3/dataManual') !== null) {
        response = await api.put(`/manual/${manual.id}`, dataManual)
        const res = await api.put(`/manual/finished/${manual.id}`, dataManual)
      } else response = await api.post('/manual', dataManual)

      cover.preview &&
        (await api.post(`/cover/${response.data.idManual}`, image))

      alertSuccess('Manual cadastrado com sucesso!')
      setDisable(false)
      setLoading(false)
      history.replace('/upgrade')
    } catch (response) {
      setDisable(false)
      alertError(response.data.error)
    }
  }
  // const handleFileData = (question, files) => {
  //   let arrayFiles = []
  //   if (answers[index] && !firstLoad) {
  //     arrayFiles = answers[index].image
  //   }

  //   answers[index] = {
  //     ...answers[index],
  //     question,
  //     alternatives: [],
  //     answer: '',
  //     notApplicable: false,
  //     image: arrayFiles,
  //     indexArray: index,
  //     note: '',
  //   }

  //   setAnswers([...answers])
  // }

  const handleAlternativeData = (
    index,
    indexAlt,
    question,
    alternative,
    firstLoad = false
  ) => {
    // console.log(
    //   index == indexAlt ? 'os index sao iguais' : 'os index NAO sao iguais'
    // )
    // console.log('indexes', index, indexAlt)
    // console.log('alternative', alternative)

    // index = posicao da pergunta na pagina, n muda
    // indexAlt = posicao de cada alternativa na pergunta
    // question = id question
    // alternative = id alternative

    let arrayAlternatives = []

    if (answers[index] && !firstLoad) {
      arrayAlternatives = answers[index].alternatives

      arrayAlternatives.map((array) => {
        if (array !== null && array.id !== alternative) array.checked = false
      })
    }

    if (arrayAlternatives[indexAlt] != undefined)
      arrayAlternatives[indexAlt] = {
        id: alternative,
        checked: !arrayAlternatives[indexAlt].checked,
        index: indexAlt,
      }
    else
      arrayAlternatives[indexAlt] = {
        id: alternative,
        checked: true,
        index: indexAlt,
      }

    answers[index] = {
      ...answers[index],
      question,
      alternatives: arrayAlternatives,
      answer: '',
      notApplicable: false,
      indexArray: index,
      note: '',
    }

    setAnswers([...answers])
  }

  const handleAlternativesData = (
    index,
    indexAlt,
    question,
    alternative,
    firstLoad = false
  ) => {
    // index = posicao da pergunta na pagina, n muda
    // indexAlt = posicao de cada alternativa na pergunta
    // question = id question
    // alternative = id alternative

    // console.log(
    //   index == indexAlt ? 'os index sao iguais' : 'os index NAO sao iguais'
    // )
    // console.log('alternative', alternative)
    // console.log('question', question)

    // console.log('indexes', index, indexAlt)

    let arrayAlternatives = []

    if (answers[index] && !firstLoad)
      arrayAlternatives = answers[index].alternatives

    if (arrayAlternatives[indexAlt]) {
      if (arrayAlternatives[indexAlt].id == alternative)
        arrayAlternatives[indexAlt] = {
          ...arrayAlternatives[indexAlt],
          checked: !arrayAlternatives[indexAlt].checked,
        }
      else
        arrayAlternatives[indexAlt] = {
          id: alternative,
          index: indexAlt,
          checked: arrayAlternatives[indexAlt].checked,
        }
    } else
      arrayAlternatives[indexAlt] = {
        id: alternative,
        index: indexAlt,
        checked: true,
      }

    answers[index] = {
      ...answers[index],
      indexArray: index,
      question,
      alternatives: arrayAlternatives,
      answer: '',
      note: '',
      notApplicable: false,
    }

    setAnswers([...answers])
  }

  const handleNoteData = (index, question, note) => {
    if (!answers[index])
      answers[index] = {
        ...answers[index],
        indexArray: index,
        question,
        alternatives: [],
        answer: '',
        note,
        notApplicable: false,
      }
    else
      answers[index] = {
        ...answers[index],
        indexArray: index,
        question,
        answer: '',
        note,
        notApplicable: false,
      }

    setAnswers([...answers])
  }

  const handleAnswerData = (index, question, answer) => {
    answers[index] = {
      ...answers[index],
      indexArray: index,
      question,
      answer,
      note: '',
      alternatives: [],
      notApplicable: false,
    }

    setAnswers([...answers])
  }

  const handleNotApplicable = (index, question, checked) => {
    if (answers[index])
      answers[index] = {
        ...answers[index],
        indexArray: index,
        question,
        answer: '',
        note: '',
        // alternatives: [],
        notApplicable: !answers[index].notApplicable,
      }
    else
      answers[index] = {
        ...answers[index],
        indexArray: index,
        question,
        answer: '',
        note: '',
        alternatives: [],
        notApplicable: true,
      }

    setAnswers([...answers])
  }

  //Terminar função
  // const returnModuleName = async () => {
  //   console.log('novoA', novoArray)
  //   novoArray.forEach((chama) => {
  //     // if (chama.numberResponse === novoArray.totalQuest) {
  //     let resp = chama.totalQuest - chama.numberResponse
  //     console.log('nr', chama.numberResponse)
  //     console.log('tq', chama.totalQuest)
  //     console.log('1', resp)
  //     let arrayNome = []
  //     if (resp > 0) arrayNome = chama.module
  //     console.log('nome', chama.module)
  //     console.log('2', arrayNome)
  //   })
  // }

  const returnModuleNotResponses = () => {
    const NovoArrayFiltrado = novoArray.filter((module) => {
      return module.totalQuest - module.numberResponse > 0
    })
    return NovoArrayFiltrado
  }

  // const handleImages = (index, question, files) => {
  //   if (answers[index])
  //     answers[index] = {
  //       ...answers[index],
  //       indexArray: index,
  //       question,
  //       answer: '',
  //       note: '',
  //       // alternatives: [],
  //       notApplicable: false,
  //       files:
  //     }
  //   else
  //     answers[index] = {
  //       ...answers[index],
  //       indexArray: index,
  //       question,
  //       answer: '',
  //       note: '',
  //       alternatives: [],
  //       notApplicable: true,
  //     }

  //   setAnswers([...answers])
  // }
  // console.log('files', files)
  return (
    <div className="form-question">
      {(disable === true || loading === true) && (
        <div className="container-loading">
          <CircularProgress />
        </div>
      )}
      {page === 0 && (
        <MainHeader
          title={
            'Para darmos início ao seu formulário escolha um design para capa do seu manual! '
          }
        />
      )}
      {page === 1 && (
        <MainHeader
          title={'Selecione um dos módulos:'}
          // percent={(totalResp * 100) / totalQuest}
          hasChart
        />
      )}
      {page > 1 && (
        <Header
          title={
            'Para darmos prosseguimento responda as questões do formulário'
          }
          handleSubmit={handleSubmit}
        />
      )}
      {loading !== true && (
        <>
          <div className="container">
            {page === 0 && (
              <FirstPage
                title={title}
                setTitle={setTitle}
                cover={cover}
                setCover={setCover}
              />
            )}
            {page === 1 && (
              <div className="Type-manual">
                <div>
                  {isEdit ? (
                    <div style={{ marginTop: '-30px', padding: '0 1rem' }}>
                      <TitleManual>{manual.title}</TitleManual>
                    </div>
                  ) : (
                    <div style={{ marginTop: '-30px', padding: '0 1rem' }}>
                      <TitleManual>{title}</TitleManual>
                    </div>
                  )}

                  {novoArray.map((modules) => (
                    <div
                      // style={{ padding: '40px 60px' }}
                      style={{ margin: '40px 0px' }}
                      onClick={() => handleModule(modules.id)}
                    >
                      <ButtonModule
                        value={modules.id}
                        imageModule={modules.image_module}
                        percent={
                          (modules.numberResponse * 100) / modules.totalQuest
                        }
                      >
                        {modules.module}
                      </ButtonModule>
                    </div>
                  ))}

                  {page === 1 && (
                    <div
                      className="container footer-question"
                      style={{ paddingTop: 0 }}
                    >
                      <div className="buttons-controller">
                        <div style={{ margin: '1rem 0' }}>
                          <Pagination
                            totalCountOfRegister={totalModulos}
                            currentPage={pagePagination}
                            registerPerPage={5}
                            onPageChange={setPagePagination}
                          />
                        </div>
                        <button
                          className="finish-button"
                          onClick={(e) => confirmSubmit(e)}
                          disabled={disable}
                        >
                          Enviar Manual
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {page > 1 &&
              questions.map((question, index) => {
                // console.log('question map', question)
                // console.log('chama', question)
                let position = (page - 2) * 3 + index
                const foundAnswer = answers.find(
                  (element) => element?.question == question?.id
                )
                // console.log('foundAnswer', foundAnswer)
                return (
                  <form key={index} style={{ minHeight: '250px' }}>
                    <div>
                      <div className="header-question">
                        <div className="number-circle">
                          <p>{(page - 2) * 3 + (index + 1)}</p>
                        </div>
                        <h1>{nl2br(question.question)}</h1>
                        {(question.response ||
                          (question.alternatives_responses != null &&
                            question.alternatives_responses[0].alternative_id !=
                              null) ||
                          question.not_applicable ||
                          //arrumar
                          (question.images != null &&
                            question.images[0].image != null)) && (
                          <i
                            className="fa fa-check"
                            aria-hidden="true"
                            style={{
                              color: 'green',
                              marginLeft: 15,
                              fontSize: 20,
                            }}
                          />
                        )}
                      </div>
                      <div className="body-question">
                        {question.alternatives !== null &&
                          question.alternatives.map((alternative, indexAlt) => (
                            <div className="form-group" key={alternative.id}>
                              {question.type_id === 1 ? (
                                <input
                                  type="radio"
                                  id={alternative.id}
                                  name={question.id}
                                  defaultChecked={
                                    alternative.checked == true
                                      ? true
                                      : foundAnswer
                                      ? foundAnswer.alternatives.find(
                                          (alt) =>
                                            alt && alt.id == alternative.id
                                        )?.checked
                                      : false
                                  }
                                  onClick={() =>
                                    handleAlternativeData(
                                      position,
                                      indexAlt,
                                      question.id,
                                      alternative.id
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  id={alternative.id}
                                  name={alternative.id}
                                  defaultChecked={
                                    alternative.checked == true
                                      ? true
                                      : foundAnswer
                                      ? foundAnswer.alternatives.find((alt) => {
                                          if (alt && alt.id == alternative.id)
                                            return (
                                              alt && alt.id == alternative.id
                                            )
                                        })?.checked
                                      : false
                                  }
                                  onClick={(e) => {
                                    handleAlternativesData(
                                      position,
                                      indexAlt,
                                      question.id,
                                      alternative.id
                                    )
                                  }}
                                />
                              )}
                              <label for={alternative.id}>
                                {nl2br(alternative.alternative)}
                              </label>
                            </div>
                          ))}
                        <div className="form-group">
                          <input
                            type={question.type_id == 1 ? 'radio' : 'checkbox'}
                            id={`notApplicable${question.id}`}
                            // name={`notApplicable${question.id}`}
                            name={question.id}
                            defaultChecked={
                              question.not_applicable == true
                                ? true
                                : foundAnswer?.notApplicable
                                ? foundAnswer.notApplicable
                                : false
                              //  == true ? true : false
                            }
                            onChange={(e) => {
                              handleNotApplicable(
                                position,
                                question.id,
                                e.target.checked
                              )
                            }}
                          />
                          <label for={`notApplicable${question.id}`}>
                            Não se aplica
                          </label>
                        </div>
                        {question.type_id !== 3 && question.type_id !== 4 ? (
                          <TextField
                            multiline
                            id={`obs${index}`}
                            name="obs"
                            label="Observação:"
                            // defaultValue={
                            //   question.response
                            //     ? question.response
                            //     : question?.commentary
                            //     ? question.commentary
                            //     : ''
                            // }
                            defaultValue={
                              question.commentary
                                ? question.commentary
                                : foundAnswer?.note != ''
                                ? foundAnswer?.note
                                : ''
                            }
                            onChange={(e) => {
                              // console.log(
                              //   'note question.commentary',
                              //   question.commentary
                              // )
                              // console.log('note foundAnswer', foundAnswer)
                              handleNoteData(
                                position,
                                question.id,
                                e.target.value
                              )
                              // console.log(answers[position].note)
                            }}
                            fullWidth
                          />
                        ) : question.type_id === 3 ? (
                          <TextField
                            multiline
                            id="resp"
                            name="resp"
                            label="R:"
                            defaultValue={
                              question.response
                                ? question.response
                                : foundAnswer?.answer
                                ? foundAnswer.answer
                                : ''
                            }
                            onChange={(e) => {
                              handleAnswerData(
                                position,
                                question.id,
                                e.target.value
                              )
                              // console.log(question.response)
                            }}
                            fullWidth
                          />
                        ) : question.type_id === 4 ? (
                          <div
                            for="file"
                            style={{
                              width: '236px',
                              height: '37px',
                              background: '#a5a7d3 0% 0% no-repeat padding-box',
                              borderRadius: '20px',
                              marginTop: '-15px',
                              opacity: '1',
                              cursor: 'pointer',
                            }}
                          >
                            <label
                              for="file"
                              style={{
                                fontSize: '15px',
                                justifyContent: 'center',
                                paddingTop: '7px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                              }}
                            >
                              Selecione suas imagens +
                            </label>
                            <InputBase
                              style={{ opacity: '0' }}
                              multiple
                              inputRef={myRefname}
                              type="file"
                              id="file"
                              onChange={(e) =>
                                addFiles(e.target.files, position, question.id)
                              }
                            >
                              Imagem
                            </InputBase>
                            <div
                              style={{
                                overflowY: 'auto',
                                maxHeight: '71px',
                              }}
                            >
                              {answers[position] &&
                                answers[position].image &&
                                answers[position].image.map((item, index) => {
                                  // console.log(item)
                                  if (item) {
                                    return (
                                      <div
                                        style={{
                                          marginRight: '0px',
                                          minWidth: '215px',
                                          minHeight: '36px',

                                          background:
                                            '#a5a7d3 0% 0% no-repeat padding-box',
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          borderRadius: '10px',
                                          opacity: '1',
                                          marginRight: '50px',
                                          fontFamily: 'Montserrat',
                                          fontWeight: '400',
                                          fontSize: '12px',
                                          letterSpacing: '0px',
                                          opacity: '1',
                                        }}
                                        key={index}
                                      >
                                        <div
                                          style={{
                                            width: '90%',
                                            paddingLeft: '8px',
                                          }}
                                        >
                                          {item.url ? item.url : item.name}
                                        </div>

                                        <div
                                          type="button"
                                          onClick={() =>
                                            deleteFile(position, index)
                                          }
                                        >
                                          x
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  </form>
                )
              })}
          </div>
          {page != 1 && (
            <div className="container footer-question">
              <div className="buttons-controller">
                {page > 2 && (
                  <a onClick={() => handleNewPage(page - 1)}>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </a>
                )}

                <button onClick={(e) => handleSubmit(e, page)}>Voltar</button>
                <button
                  style={{
                    marginLeft: 15,
                  }}
                  onClick={
                    (e) => handleSubmitNext(e, page)
                    // handleNewPage(page + 1)
                  }
                >
                  Salvar
                </button>
                {page === 0 && (
                  <button
                    className="next-button"
                    disabled={disable}
                    // onClick={(e) => handleNewPage(page + 1)}
                    onClick={() => handleNewPage(page + 1)}
                  >
                    Prosseguir
                  </button>
                )}
                {/* {page === lastPage - 1 && (
                  <button
                    className="finish-button"
                    onClick={(e) => confirmSubmit(e)}
                    disabled={disable}
                  >
                    Finalizar
                  </button>
                )} */}
                {page != lastPage - 1 && page > 0 && (
                  <a onClick={() => handleNewPage(page + 1)}>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                )}
              </div>
              <div className={`access-page `}>
                <p>Acessar Página:</p>
                <input
                  type="number"
                  max={lastPage}
                  min={1}
                  value={chosenPage}
                  onChange={(e) =>
                    e.target.value <= lastPage && setChosenPage(e.target.value)
                  }
                />

                <a onClick={() => handleNewPage(chosenPage - 1)}>
                  <i className="fa fa-search" aria-hidden="true" />
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
