import React, { useEffect, useState } from 'react'
import ListCategory from '../../Layouts/Category/ListCategory'


export default function Category() {
  const [categorie, setCategorie] = useState('')
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')

  const handleEditCategory = (data) => {
    setEdit(true)
    setCategorie(data.categorie)
    setId(data.id)
  }

  return (
    <>
      <ListCategory handleEditCategory={handleEditCategory} />
    </>
  )
}