import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { alertInfo, alertError, alertSuccess } from '../../../utils/Alert'
import { logout } from '../../../services/auth'

import ModalQRCode from '../../../Components/Modal'
import api from '../../../services/api'

export default function Questions({ manuals }) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [urlManual, setUrlManual] = useState('')

  const HandleNewManual = () => {
    localStorage.removeItem('@BV3/dataManual')
    history.push('/type')
  }

  const handleContinueResponse = (data, idCategorie) => {
    if (data.is_finished) {
      alertInfo(
        'Seu manual ainda está sendo analisado pelo administrador. Aguarde mais um pouco!'
      )
      return
    }

    const newIdCat = localStorage.setItem('@BV3/idCategorie', idCategorie)

    localStorage.setItem('@BV3/dataManual', JSON.stringify(data))
    history.push('/form/edit')
  }

  const handleLogout = () => {
    logout()
    window.location.href = 'http://bv3engenharia.com.br/'
  }

  const handleOpenManual = (manual) => {
    const url = `manual/${manual.id}/${manual.title
      .replace(/ /g, '&&')
      .replace(/\//g, '&c')}`
    setUrlManual(url)
    setOpen(true)
  }

  const handleReject = (justification, id, e, data, tableRef) => {
    Swal.fire({
      icon: 'error',
      html: `Manual recusado pelo Administrador! <br/><br/> Motivo: ${justification}`,
      confirmButtonText: `Adicionar Informações`,
      showDenyButton: true,
      denyButtonText: `Editar Manual`,
      denyButtonColor: '#2778c4',
      showCancelButton: true,
      cancelButtonText: 'Ok',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Adicione as Informações:',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          preConfirm: (justification) => {
            handleResendManual(id, e, data, tableRef, justification)
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
      }
      if (result.isDenied) {
        await handleEditManual(id)

        window.setTimeout(() => {
          history.push(`/form/edit`)
        }, 1000)
      }
    })
  }

  const handleResendManual = async (id, e, data, tableRef, justification) => {
    try {
      const response = await api.put(`/resend/${id}`, { justification })

      alertSuccess(response.data.success)
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleEditManual = async (id) => {
    try {
      const manualList = manuals.find((manual) => manual.id === id)
      localStorage.setItem('@BV3/dataManual', JSON.stringify(manualList))
      const newIdCat = localStorage.setItem(
        '@BV3/idCategorie',
        manualList.categorie_id
      )
      const response = await api.put(`/republish/${id}`)
      alertSuccess(response.data.success)
    } catch (response) {
      alertError(response.data.error)
    }
  }

  return (
    <>
      <ModalQRCode open={open} setOpen={setOpen} url={urlManual} />
      <div id="nw_home">
        {/* <!-- BACKGROUND IMAGE --> */}
        <div className="nw_background">
          {/* <!-- SLIDER --> */}
          <div className="nw_image_cut">
            <div className="nw_background_image"></div>
          </div>
          {/* <!-- LAYERS --> */}
          <div className="nw_logout">
            <a onClick={handleLogout}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <p>Sair</p>
            </a>
          </div>
          <div className="nw_layers wow fadeInUp">
            {/* <!-- LAYER 1 --> */}
            <div className="nw_layer_1">
              Gere seu manual
              <br /> de forma prática e segura!
            </div>
            {/* <!-- LAYER 2 --> */}
            <div className="nw_layer_2">
              Basta responder algumas perguntas sobre seu <br />
              imóvel e aguardar a validação.
            </div>
            {/* <!-- LAYER 3 --> */}
            <div className="nw_layer_3">
              <a onClick={() => HandleNewManual()}>Gerar Manual</a>
            </div>
          </div>
        </div>
        <div className="container nw_type_1 nw_container">
          {/* <!-- TITLE --> */}
          {/* <!-- CONTENT --> */}
          <div className="row nw_services">
            <div className="col-md-4 nw_services_row">
              <div className="nw_services_container ">
                <div className="nw_services_content">
                  <div className="nw_icon">
                    <img
                      className="wow fadeInUp animated"
                      src="./assets/images/icone_andamento.png"
                      alt="icon-1.png"
                    />
                  </div>
                  <div className="nw_title">
                    <h1 className="wow fadeInUp animated">
                      Manuais em Andamento
                    </h1>
                  </div>
                  <div className="nw_text">
                    {manuals.map(
                      (manual, index) =>
                        manual.is_valid == 0 && (
                          <a
                            onClick={() =>
                              handleContinueResponse(
                                manual,
                                manual.categorie_id
                              )
                            }
                            key={manual.id}
                          >
                            <p className="wow fadeInUp animated">
                              {manual.title}
                            </p>
                          </a>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 nw_services_row">
              <div className="nw_services_container">
                <div className="nw_services_content">
                  <div className="nw_icon wow fadeInUp">
                    <img
                      className="wow fadeInUp animated"
                      src="./assets/images/icone_finalizadas.png"
                      alt="icon-2.png"
                    />
                  </div>
                  <div className="nw_title">
                    <h1 className="wow fadeInUp animated">
                      Manuais Finalizados
                    </h1>
                  </div>
                  <div className="nw_text">
                    {manuals.map(
                      (manual, index) =>
                        manual.is_valid == 1 && (
                          <a
                            onClick={() => handleOpenManual(manual)}
                            key={manual.id}
                            target="_blank"
                          >
                            <p className="wow fadeInUp animated">
                              {manual.title}
                            </p>
                          </a>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 nw_services_row">
              <div className="nw_services_container">
                <div className="nw_services_content">
                  <div className="nw_icon">
                    <img
                      className="wow fadeInUp animated"
                      src="./assets/images/icone_recusados.png"
                      alt="icon-3.png"
                    />
                  </div>

                  <div className="nw_title">
                    <h1 className="wow fadeInUp animated">Manuais Recusados</h1>
                  </div>
                  <div className="nw_text">
                    {manuals.map(
                      (manual, index) =>
                        manual.is_valid == -1 && (
                          <a
                            onClick={() =>
                              handleReject(manual.justification, manual.id)
                            }
                            key={manual.id}
                          >
                            <p className="wow fadeInUp animated">
                              {manual.title}
                            </p>
                          </a>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
