import React, { useEffect, useState } from 'react'
import useSWR from 'swr'

import { useParams } from 'react-router-dom'
import api from '../../../services/api'
import { alertError } from '../../../utils/Alert'

import Cover from '../../Layouts/Manual/Cover'
import Body from '../../Layouts/Manual/Body'
import Sumario from '../../Layouts/Manual/Sumario'

const fetcher = (...params) => api.get(...params)

export default function Manual() {
  const [responses, setResponses] = useState([])
  const [manual, setManual] = useState({})
  const [modules, setModules] = useState([])
  const [questionsPerModules, setQuestionsPerModules] = useState()
  const { id } = useParams()
  const { title } = useParams()
  const { data, error } = useSWR(
    `/manual-complete/${id}?title=${title
      .replace(/&&/g, ' ')
      .replace(/&c/g, '/')}`,
    fetcher
  )

  useEffect(() => {
    if (data) {
      console.log(data)
      setResponses(data.data.responses)
      setModules(data.data.modules)
      setQuestionsPerModules(data.data.questions_per_module)
      setManual(data.data.manual)
      document.title = data.data.manual.title
    } else if (error) {
      alertError('Ops! Parece que encontramos um erro.')
    }
  }, [data])

  return (
    <div className="manual-reader">
      <Cover manual={manual} />
      <Sumario
        modules={modules}
        questionsPerModules={questionsPerModules}
        manualId={id}
      />
      <Body
        responses={responses}
        questionsPerModules={questionsPerModules}
        modules={modules}
      />
    </div>
  )
}
