import React, { lazy } from 'react'
import {
  HashRouter,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { isAuthenticated } from '../services/auth'
import NotFound from '../Views/Pages/PagesError/NotFound'
import routing from './routing'
import { AdminRoute, UserRoute } from './type'

const Dashboard = lazy(() => import('../Views/Layouts/Dashboard'))

const loading = (
  <div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
)

export default function Routes() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/admin">
              {isAuthenticated() &&
              localStorage.getItem('@BV3/access') === '999' ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/admin/login" />
              )}
            </Route>
            {routing.public.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            {routing.user.map((route, index) => (
              <UserRoute
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            <AdminRoute path="/dashboard" component={Dashboard} />
            <NotFound component={NotFound} />
          </Switch>
        </Router>
      </React.Suspense>
    </HashRouter>
  )
}
