import React, { useState, useEffect } from 'react'
import { nl2br } from '../../../utils/breakLine'
import { url } from '../../../services/api'

export default function Body({ responses, questionsPerModules, modules }) {
  // console.log('a', responses)
  const [questions_per_module, setQuestionsPerModule] = useState([])
  useEffect(() => {
    if (!!questionsPerModules)
      setQuestionsPerModule(Object.entries(questionsPerModules))
  }, [questionsPerModules])

  return (
    <>
      <div
        className="container body-manual"
        style={{
          maxWidth: '900px',
          margin: '0 auto',
          marginTop: 16,
          paddingBlock: 32,
        }}
      >
        {questions_per_module.map((keyvalueArray) => {
          // keyvalue = [id_modulo, [{questões}]]
          const idModulo = keyvalueArray[0]
          const questoes = keyvalueArray[1]
          const questoes_ids = questoes.map((questao) => questao.id)

          const moduleName = modules.find(
            (module) => module.id === Number(idModulo)
          ).module

          return (
            <>
              {questoes_ids.length > 0 && (
                <h1 style={{ paddingInline: '20px' }}>{moduleName}</h1>
              )}
              {responses.map((response, index) => {
                const titulo_sumario = questoes.find((questao) => {
                  return questao.id === response.question
                })

                return (
                  questoes_ids.includes(response.question) && (
                    <div className="text-manual">
                      <h3 style={{ color: 'darkblue' }}>
                        {!!titulo_sumario && titulo_sumario?.titulo_sumario}
                      </h3>
                      {response.alternatives.length > 0 &&
                        [...new Set(response.alternatives)].map(
                          (alternative) => (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: nl2br(alternative),
                              }}
                            ></p>
                          )
                        )}

                      {response.answer != '' && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: nl2br(response.answer),
                          }}
                        ></p>
                      )}
                      {response.note != '' && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: nl2br(response.note),
                          }}
                        ></p>
                      )}
                      {response.image.length > 0 && (
                        <div className="image-body">
                          {response.image.length > 0 &&
                            [...new Set(response.image)].map((image) => (
                              <img src={url + image} alt={"img"} />
                            ))}
                        </div>
                      )}
                    </div>
                  )
                )
              })}
            </>
          )
        })}
      </div>
    </>
  )
}
