import React, { useState } from 'react'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'
import { OrderList } from '../../../utils/Question.json'
import { nl2br } from '../../../utils/breakLine'

import DropZone from '../../../Components/DropZone'
import FileList from '../../../Components/FileList'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

export default function FormEditManual({
  questions,
  setQuestions,
  data,
  setData,
  answers,
  page,
}) {
  const handleUpload = async (files, index) => {
    // console.log({ data, files, index })
    const uploadedFiles = {
      file: files[0],
      name: files[0].name,
      preview: URL.createObjectURL(files[0]),
    }

    data[index] = {
      ...data[index],
      image: uploadedFiles,
    }

    await setData([...data])
  }

  const handleDeleteUpload = (index) => {
    data[index] = {
      ...data[index],
      image: '',
    }

    setData([...data])
  }

  const onChangeValue = (e, index) => {
    const { value } = e.target
    const { name } = e.target

    data[index] = {
      ...data[index],
      [name]: value,
    }

    setData([...data])
  }

  return (
    <div className="p-3">
      {questions.map((question, index) => {
        let position = (page - 1) * 4 + index

        return (
          <Grid container spacing={3} key={index}>
            <div className="header-question">
              <div className="number-circle">
                <p>{(page - 1) * 4 + (index + 1)}</p>
              </div>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: nl2br(question.question) }}
              ></p>
            </div>
            {question.alternatives.length > 0 ? (
              <>
                <div className="resp-question">
                  {question.alternatives.map((alternative, index) => (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html:
                          OrderList[alternative.index] +
                          ' - ' +
                          nl2br(alternative.alternative),
                      }}
                    ></p>
                  ))}
                </div>
                <Grid item xs={12} sm={12}>
                  <TextField
                    multiline
                    required
                    id="note"
                    name="note"
                    label="Obs:"
                    value={data[position].note}
                    onChange={(e) => onChangeValue(e, position)}
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              question.notApplicable != true && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    multiline
                    required
                    id="answer"
                    name="answer"
                    label="R:"
                    value={data[position].answer}
                    onChange={(e) => onChangeValue(e, position)}
                    fullWidth
                  />
                </Grid>
              )
            )}
            {question.notApplicable == true ? (
              <div className="resp-question mb-5">
                <p>Não aplicável</p>
              </div>
            ) : (
              <Grid item xs={12} className="m-4 d-flex justify-content-center">
                <Grid item xs={12} sm={5}>
                  <DropZone onUpload={handleUpload} index={position} />
                  {data[position].image != '' && (
                    <FileList
                      index={position}
                      files={data[position].image}
                      handleDeleteUpload={handleDeleteUpload}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )
      })}
    </div>
  )
}
