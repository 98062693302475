import React from 'react'
import api from '../../services/api'

import { Container, Text } from './style'

const Title = ({ children, imageModule, percent, ...rest }) => {
  return (
    <Container {...rest}>
      <Text>{children}</Text>
    </Container>
  )
}

export default Title
