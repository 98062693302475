import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    paddingBottom: 5,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#23394a',
  },
  form: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: -15,
  },
  buttonsCancel: {
    marginLeft: 15,
    marginBottom: 30,
  },
  buttonCancel: {
    backgroundColor: '#ddd',
    color: '#000',
    '&:hover': {
      backgroundColor: '#23394a',
      color: '#fff',
    },
  },
  button: {
    backgroundColor: '#23394a',
  },
  title: {
    color: '#fff',
    paddingLeft: 10,
  },
}))

export default useStyles
