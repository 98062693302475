import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import { QuestionTypes, OrderList } from '../../../utils/Question.json'
import { checkNulls } from '../../../utils/CheckNulls'
import { nl2br } from '../../../utils/breakLine'
import api from '../../../services/api'

import Form from '../../../Components/Form'
import FormQuestion from '../../Layouts/FormQuestion'

export default function RegisterQuestion() {
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [question, setQuestion] = useState('')
  const [type, setType] = useState('')
  const [selected, setSelected] = useState([
    { categorie_id: '', modules_id: '', position: '' },
  ])
  const [tituloSumario, setTituloSumario] = useState('')
  const [categorie_id, setCategorie_id] = useState('')
  const [modules_id, setModules_id] = useState('')
  const [alternatives, setAlternatives] = useState([
    { alternative: '', alternative_text: '' },
  ])

  const handleSubmit = async (e) => {
    try {
      setLoading(true)
      setDisable(true)
      if (type === '' || question === '') {
        alertInfo('Por favor, preencha os campos vazio!')
        setDisable(false)
        setLoading(false)
        return
      }

      let response
      if (type === 3 || type === 4) {
        response = await api.post('/questions', {
          question,
          type_id: type,
          addcategorie_module: selected,
          titulo_sumario: tituloSumario,
        })
        // console.log(response)
      } else {
        const existeNull = await checkNulls(alternatives)
        if (existeNull === true) {
          alertInfo('Por favor, preencha todas as alternativas!')
          setDisable(false)
          setLoading(false)
          return
        }

        // for(var i=0; i< alternatives.length; i++){
        //   alternatives[i] = {
        //     alternative: nl2br(alternatives[i].alternative),
        //     alternative_text: nl2br(alternatives[i].alternative_text)
        //   }

        //   setAlternatives
        // }

        response = await api.post('/questions', {
          question,
          type_id: type,
          alternatives,
          addcategorie_module: selected,
        })
      }

      if (response.data.success) {
        alertSuccess(response.data.success)
        setQuestion('')
        setType('')
        setTituloSumario('')
        setAlternatives([{ alternative: '', alternative_text: '' }])
        setSelected([{ categorie_id: '', modules_id: '', position: '' }])
        setDisable(false)
        setLoading(false)
      }
    } catch (response) {
      setDisable(false)
      setLoading(false)
      alertError(response.data.error)
    }
  }

  return (
    <>
      {loading == true && (
        <div className="loading-dashboard">
          <CircularProgress />
        </div>
      )}
      <Form
        title={'Cadastro das Questões'}
        handleSubmit={handleSubmit}
        titleButton={'Cadastrar'}
        disable={disable}
      >
        <FormQuestion
          tituloSumario={tituloSumario}
          setTituloSumario={setTituloSumario}
          question={question}
          setQuestion={setQuestion}
          type={type}
          setType={setType}
          alternatives={alternatives}
          setAlternatives={setAlternatives}
          selected={selected}
          setSelected={setSelected}
        />
      </Form>
    </>
  )
}
