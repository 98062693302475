import React, { useState, useEffect, useRef } from 'react'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import useStyles from './style'
import { Category } from '@material-ui/icons'

export default function FormModule({
  modules,
  setModules,
  categorie_id,
  setCategorie_id,
  edit,
  setEdit,
  id,
  setId,
  editMod,
  setEditMod,
  oldModuleName,
  setOldModuleName,
}) {
  const myRefname = useRef()
  const [selectCategory, setSelectCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const classes = useStyles()
  const [disable, setDisable] = useState(false)
  const [file, setFile] = useState()
  const loadCategory = async () => {
    let response = await api.get(
      `/categories?per_page=10&page=1&columnOrder=categorie&order=asc&search=`
    )
    setSelectCategory(response.data.categories)
  }
  useEffect(() => {
    loadCategory()
    if (edit === false) {
      setCategorie_id('')
    }
  }, [])
  // useEffect(async () => {
  //   if (edit === true) {
  //     console.log('chama')
  //     const { data } = await api.post(`/modules/name`, { nome: modules })
  //     console.log('dale', data)
  //     setSelectCategory(data.modules)
  //   }
  // }, [edit])
  useEffect(async () => {
    if (editMod === true) {
      const { data } = await api.post(`/modules/name`, { nome: modules })

      setSelectCategory(data.modules)
      setEditMod(false)
      setSelectedCategory(data.modules.map((cat) => cat.id))
    }
  }, [editMod])

  // const ITEM_HEIGHT = 48
  // const ITEM_PADDING_TOP = 8
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // }

  const handleSubmit = async (e) => {
    try {
      setDisable(true)
      if (modules === '' || selectedCategory === []) {
        alertInfo('Por favor preencha todos os campos!')
        setDisable(false)
        return
      }

      if (edit === true) {
        if (file) {
          let data = new FormData()
          data.append('file', file)
          await api.post(`/image-module/${oldModuleName}`, data)
        }
        const { data } = await api.put('/modules/edit/name', {
          oldModuleName,
          module: modules,
        })
        const notSelect = selectCategory
          .filter((item) => {
            return selectedCategory.includes(item.id) === false
          })
          .map((item) => item.id)
        if (notSelect.length > 0) {
          await api.post('/modules-delete', {
            arrayIds: notSelect,
          })
        }
      } else {
        const response = await api.post('/modules', {
          module: modules,
          categorie_id: selectedCategory,
        })
        let data = new FormData()
        data.append('file', file)
        response.data.id.map(async function (item) {
          const imagem = await api.post(`/image-module/${modules}`, data)
        })
      }

      if (edit === true) alertSuccess('Atualizado com sucesso', true)
      else alertSuccess('Cadastrado com sucesso', true)

      // this.inputRef = ''
    } catch (response) {
      setDisable(false)
      alertError(response.data.error)
    } finally {
      setOldModuleName(localStorage.removeItem('@BV3/moduleName'))
      setModules('')
      setCategorie_id('')
      setSelectedCategory([])
      setDisable(false)
      setEdit(false)
      setId('')
      setFile(null)
    }
  }

  const theme = useTheme()

  const handleCancelUpdate = (e) => {
    setModules('')
    setSelectedCategory([''])
    setCategorie_id('')
    setEdit(false)
    setId('')
    setFile(null)
  }
  function getStyles(name, theme) {
    return {
      fontWeight:
        categorie_id.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }
  // const handleChange = (event) => {
  //   console.log('categorieid', selectedCategory)

  //   const [categoria_id] = event.target.value
  //   console.log('value', categoria_id)

  //   const array = [...selectedCategory]
  //   console.log('array', array)
  //   // let categoria = selectedCategory.indexOf(value[value.length - 1])
  //   let categoria = selectedCategory.findIndex((cat) => {
  //     return cat === categoria_id
  //   })
  //   console.log('cat modulo', categoria)
  //   if (categoria != -1) {
  //     array.splice(categoria, 1)
  //     console.log('splice', array)
  //     setSelectedCategory(array)
  //   } else {
  //     // array.push(value[value.length - 1])
  //     array.push(categoria_id)
  //     console.log('splice', array)
  //     setSelectedCategory(array)
  //     // console.log('array', [...array, value])
  //   }

  //   setCategorie_id(array)
  // }

  const handleChange = (event) => {
    const array = event.target.value

    setSelectedCategory(array)
  }

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography className={classes.title} component="h3" variant="h5">
            Cadastro de Módulos
          </Typography>
        </div>
        <div className={classes.form}>
          <Grid container spacing={3} alignItems={'flex-end'}>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="outlined"
                required
                // inputProps={{ maxLength: 30 }}
                id="modules"
                name="modules"
                label="Módulo:"
                fullWidth
                onChange={(e) => setModules(e.target.value)}
                value={modules}
                autoComplete="modules"
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormControl
                required
                className={classes.formControl}
                variant="outlined"
                fullWidth
              >
                <InputLabel id="categorie">Categoria de manuais:</InputLabel>

                {/* <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // multiple
                  value={categorie_id}
                  onChange={handleChange}
                  // input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {selectCategory.map((category) => (
                    <MenuItem key={category} value={category.id}>
                      {category.categorie}
                      <Checkbox checked={categorie_id.indexOf(category) > -1} />
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
                </Select> */}

                <Select
                  className={classes.select}
                  // fullWidth
                  label="Categoria de manuais:"
                  labelId="categorie"
                  multiple
                  id="demo-simple-select"
                  value={selectedCategory}
                  onChange={handleChange}
                >
                  {selectCategory.map((category) => (
                    <MenuItem
                      value={category.id}
                      style={getStyles(category.id, theme)}
                    >
                      {category.categorie}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormControl required className={classes.formControl} fullWidth>
              <InputBase
                inputRef={myRefname}
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              >
                Imagem
              </InputBase>
              {/* <Button
              variant="contained"
              color="primary"
              onClick={(e) => {myRefname.current.click();console.log(myRefname)}}
              disabled={disable}
              className={classes.button}
            >
              Escolher arquivo
            </Button>  */}
            </FormControl>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
              disabled={disable}
              className={classes.button}
            >
              {edit === true ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </div>
        </div>
        {edit === true && (
          <div className={classes.buttonsCancel}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleCancelUpdate(e)}
              disabled={disable}
              className={classes.buttonCancel}
            >
              Cancelar Atualização
            </Button>
          </div>
        )}
      </Paper>
    </main>
  )
}
