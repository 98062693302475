import React, { useEffect, useState } from 'react'
import Flex from '../../../Components/Flex'
import styles from './sumario.module.css'
import { Button, Box } from '@material-ui/core'
import saveIcon from '../../../assets/save-icon.svg'
import Swal from 'sweetalert2'
import api from '../../../services/api'
import { toast } from 'react-toastify'

const Sumario = ({ modules, questionsPerModules, manualId }) => {
  const [questions_per_module, setQuestionsPerModule] = useState([])
  const [paginasSumario, setPaginasSumario] = useState([])
  const [paginasSumarioRecebida, setPaginasSumarioRecebida] = useState([])

  useEffect(() => {
    if (!!questionsPerModules)
      setQuestionsPerModule(Object.entries(questionsPerModules))
  }, [questionsPerModules])

  useEffect(() => {
    const getPaginasSumario = async () => {
      try {
        const response = await api.get(`/sumario/${manualId}`)
        // console.log(response)
        setPaginasSumario(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getPaginasSumario()
  }, [])

  const onChangeInput = (event, index) => {
    const newPaginasSumario = [...paginasSumario]
    newPaginasSumario[index] = event.target.value
    // newPaginasSumario.push(event.target.value)
    const b = newPaginasSumario.filter((p) => {
      return Number(p > 0)
    })
    setPaginasSumario(newPaginasSumario)
  }

  const fireModal = async () => {
    const { isConfirmed, isDismissed } = await Swal.fire({
      title: 'Deseja salvar o sumário?',
      text: 'O sumário será salvo e ficará disponível aos usuários.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Salvar',
      cancelButtonText: 'Cancelar',
    })

    if (isConfirmed) {
      try {
        saveSumario(paginasSumario)
        toast.success('Sumário salvo com sucesso!')
      } catch (error) {
        toast.error('Erro ao salvar sumário!')
      }
    }
  }

  const saveSumario = async (paginasSumario) => {
    try {
      const response = await api.post('/sumario', {
        paginas: JSON.stringify(paginasSumario),
        manual_id: manualId,
      })
      // alert(paginasSumario)
    } catch (error) {
      console.log(error)
    }
  }

  const access = localStorage.getItem('@BV3/access')

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: '2rem auto 0 auto',
        textAlign: 'center',
        height: '1200px',
        width: '100%',
        // boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.33)',
        // border: '2px solid rgba(0,0,0,0.1)',
      }}
    >
      <h1 className={styles.header_title}>Sumário</h1>
      <p className={styles.bv3_manuais}>Por BV3 - Manuais</p>
      <div className={styles.sumario_container}>
        {questions_per_module.map((keyvalueArray) => {
          // keyvalue = [id_modulo, [{questões}]]
          const idModulo = keyvalueArray[0]
          const questoes = keyvalueArray[1]

          const moduleName = modules.find(
            (module) => module.id === Number(idModulo)
          ).module

          const questoesPresentesNoSumario = questoes.filter((questao) => {
            return !!questao.titulo_sumario && questao.titulo_sumario != ''
          })

          console.log('aa', questoesPresentesNoSumario)

          // const paginasSum = [...paginasSumarioRecebida]
          // const indiceAtual = paginasSum.unshift()
          // setPaginasSumarioRecebida([...paginasSum])

          return (
            questoes.length > 0 && (
              <ul>
                <li>
                  <li className={styles.listItem}>{moduleName}</li>
                  <ul>
                    {questoesPresentesNoSumario.map((questao, index) => {
                      return (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <li className={styles.listSubItem}>
                            {questao.titulo_sumario}
                          </li>

                          {access == 1 ? (
                            <p>{paginasSumario[index]}</p>
                          ) : (
                            <input
                              value={paginasSumario[index]}
                              className={styles.input}
                              onChange={(event) => onChangeInput(event, index)}
                            />
                          )}
                        </Flex>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            )
          )
        })}
      </div>
      {access != 1 && (
        <Box
          onClick={() => fireModal()}
          style={{ position: 'fixed', bottom: 35, right: 35, zIndex: 1000 }}
        >
          <img
            className={styles.svg}
            src={saveIcon}
            width="60px"
            height="60px"
          />
        </Box>
      )}
    </div>
  )
}

export default Sumario
