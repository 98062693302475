import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#23394a',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  button: {
    backgroundColor: '#23394a',
  },
  title: {
    color: '#fff',
    paddingLeft: 10,
  },
  form: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
}))

export default useStyles
