import React, { useState } from 'react'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import useStyles from './style'

export default function FormUser({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  edit,
  setEdit,
  id,
  setId,
}) {
  const classes = useStyles()
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [disable, setDisable] = useState(false)

  const handleSubmit = async (e) => {
    try {
      setDisable(true)
      if (name === '' || email === '') {
        alertInfo('Por favor preencha todos os campos!')
        setDisable(false)
        return
      }

      if (password !== '' && password.length < 6) {
        alertInfo('Por favor, insira uma senha maior que 6 dígitos')
        setDisable(false)
        return
      }

      const usuario = email.indexOf('@')
      const dominio = email.indexOf('@') + 1

      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
      if (!pattern.test(email)) {
        alertInfo('Informe um e-mail valido!')
        return
      }

      if (edit === true) {
        if (password !== '')
          await api.put(`/user/${id}`, {
            name,
            email,
            password,
          })
        else
          await api.put(`/user/${id}`, {
            name,
            email,
          })
      } else {
        if (password === '') {
          alertInfo('Por favor preencha todos os campos!')
          setDisable(false)
          return
        }

        const response = await api.post('/user/register', {
          name,
          email,
          password,
        })
      }

      setName('')
      setEmail('')
      setPassword('')
      setDisable(false)
      setEdit(false)
      setId('')

      if (edit === true) alertSuccess('Atualizado com sucesso')
      else alertSuccess('Cadastrado com sucesso')
    } catch (response) {
      setDisable(false)
      alertError(response.data.error)
    }
  }

  const handleCancelUpdate = (e) => {
    setName('')
    setEmail('')
    setPassword('')
    setEdit(false)
    setId('')
  }

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography className={classes.title} component="h3" variant="h5">
            Cadastro de Usuários
          </Typography>
        </div>
        <div className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <TextField
                required
                id="name"
                name="name"
                label="Nome:"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="email"
                name="email"
                label="E-mail:"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  Senha:
                </InputLabel>
                <Input
                  required
                  id="standard-adornment-password"
                  type={visiblePassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setVisiblePassword(!visiblePassword)}
                      >
                        {visiblePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
              disabled={disable}
              className={classes.button}
            >
              {edit === true ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </div>
        </div>
        {edit === true && (
          <div className={classes.buttonsCancel}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleCancelUpdate(e)}
              disabled={disable}
              className={classes.buttonCancel}
            >
              Cancelar Atualização
            </Button>
          </div>
        )}
      </Paper>
    </main>
  )
}
