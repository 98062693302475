import React from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Upgrade({
  handleSelectServices,
  services,
  total,
  handleSubmit,
}) {
  const history = useHistory()

  const handleOpenModal = (pop) => {
    if (pop == 1 || pop == 2)
      Swal.fire({
        html: `${
          pop == 1
            ? `<p class="message-pop-up" style="margin-top: 20px">Que tal um toque profissional no design do manual?</p>
      <p class="message-pop-up">Oferecemos um serviço de personalização diretamente com especialistas designers que vão elevar o nível visual da capa do manual.</p>
      <p class="message-pop-up">Com isto, você terá um produto com uma capa totalmente personalizada conforme a Branding do projeto, deixando-a mais interativa e elegante.</p>`
            : `<p class="message-pop-up" style="margin-top: 20px">Vamos mostrar para o público que o empreendimento possui um manual?</p>
      <p class="message-pop-up"> Com este serviço, você terá uma peça publicitária com informações sobre o manual do empreendimento totalmente personalizada. Divulgue nas redes sociais, website e onde mais quiser sobre a novidade.</p>`
        }`,
        padding: '0.6em',
        background: '#263b4c',
        confirmButtonText: 'Fechar',
        confirmButtonColor: '#263b4c',
        customClass: {
          popup: 'pop-up-upgrade',
          actions: 'pop-up-upgrade-actions',
        },
      })
    else
      Swal.fire({
        html: `<video width="100%" controls autoplay>
        <source src=${
          pop == 3
            ? '../assets/videos/boas-praticas.mp4'
            : pop == 4
            ? '../assets/videos/praticas-narracao.mp4'
            : pop == 5
            ? '../assets/videos/Maia-1.mp4'
            : pop == 6
            ? '../assets/videos/Maia-2.mp4'
            : '../assets/videos/Maia-3.mp4'
        } type="video/mp4">
        </video>`,
        padding: '30px 0 0.6em 0',
        background: '#263b4c',
        confirmButtonText: 'Fechar',
        confirmButtonColor: '#263b4c',
        customClass: {
          popup: 'pop-up-video',
          actions: 'pop-up-video-actions',
          content: 'pop-up-video-content',
        },
      })
  }

  return (
    <div className="block-upgrade">
      <div className="container">
        <div className="header">
          <h1>Faça um upgrade!</h1>
          <p>
            Oferecemos as seguintes opções para ampliar ainda mais a experiência
            de seu cliente com o produto que adquiriu.{' '}
          </p>
        </div>
        <div className="content box">
          <div className="header-content col-lg-3 col-ml-3 col-sm-12">
            <img src="../assets/images/creator.svg" className="img-2" />
            <img src="../assets/images/logo-mind.png" className="img-3" />
          </div>
          <div className="content-info description col-lg-5 col-ml-5 col-sm-12">
            <h2>Desenvolvimento de website</h2>
            <p>Para seu manual, empreendimento, construtora ou empresa</p>
          </div>

          <div className="content-info justify-content-center col-lg-4 col-ml-4 col-sm-12">
            <div className="price-content">
              <div className="container-price-month">
                <span style={{ marginRight: '3px' }}>A partir de</span>
                <h2>R$500,00</h2>
                <span>/mês</span>
              </div>
              <p style={{ marginBottom: '0px' }}>Tenho interesse!</p>
            </div>
            <input
              type="checkbox"
              className="check-custom toggle-switch"
              checked={services.website}
            />
            <span
              className="check-toggle"
              onClick={(e) => handleSelectServices('website')}
            ></span>
          </div>
        </div>
        <div className="content box-1">
          <div className="header-content col-lg-4 col-ml-4 col-sm-12">
            <img src="../assets/images/designer-girl.svg" />
            <h3>Personalização</h3>
          </div>
          <div className="content-info col-lg-4 col-ml-4 col-sm-12">
            <div className="price-content">
              <p>Personalização da capa do manual por um designer</p>
              <h2>R$250,00</h2>
            </div>
            <div className="selected-content">
              <input
                name="cover"
                type="checkbox"
                className="check-custom toggle-switch"
                checked={services.cover}
              />
              <span
                className="check-toggle"
                onClick={(e) => handleSelectServices('cover')}
              ></span>
              <a
                onClick={() => {
                  handleOpenModal(1)
                }}
              >
                Saber mais sobre isso
              </a>
            </div>
          </div>

          <div className="content-info col-lg-4 col-ml-4 col-sm-12">
            <div className="price-content">
              <p>Banner para divulgar o empreendimento</p>
              <h2>R$150,00</h2>
            </div>
            <div className="selected-content">
              <input
                name="banner"
                type="checkbox"
                className="check-custom toggle-switch"
                checked={services.banner}
              />
              <span
                className="check-toggle"
                onClick={(e) => handleSelectServices('banner')}
              ></span>
              <a
                onClick={() => {
                  handleOpenModal(2)
                }}
              >
                Saber mais sobre isso
              </a>
            </div>
          </div>
        </div>
        <div className="content box-2">
          <div className="header-content col-lg-4 col-ml-4 col-sm-12">
            <img src="../assets/images/video-files.svg" className="img-2" />
            <h3>
              Vídeo <br /> Institucional
            </h3>
          </div>
          <div className="content-info col-lg-4 col-ml-4 col-sm-12">
            <div className="price-content">
              <p>Recomendações de boas práticas do manual</p>
              <h2>R$800,00</h2>
            </div>
            <div className="selected-content">
              <input
                name="pratics"
                type="checkbox"
                className="check-custom toggle-switch"
                checked={services.pratics}
              />
              <span
                className="check-toggle"
                onClick={(e) => handleSelectServices('pratics')}
              ></span>
              <a
                onClick={() => {
                  handleOpenModal(3)
                }}
              >
                Ver exemplo
              </a>
            </div>
          </div>

          <div className="content-info col-lg-4 col-ml-4 col-sm-12">
            <div className="price-content">
              <p style={{ marginBottom: '2.35rem' }}>
                Boas práticas + Narração
              </p>

              <h2>R$999,90</h2>
            </div>
            <div className="selected-content">
              <input
                name="praticsNarration"
                type="checkbox"
                className="check-custom toggle-switch"
                checked={services.praticsNarration}
              />
              <span
                className="check-toggle"
                onClick={(e) => handleSelectServices('praticsNarration')}
              ></span>

              <a
                onClick={() => {
                  handleOpenModal(4)
                }}
              >
                Ver exemplo
              </a>
            </div>
          </div>
        </div>
        <div className="content box-3">
          <div className="header-content col-lg-4 col-ml-3 col-sm-12">
            <img src="../assets/images/creator-amico.svg" />
            <div className="container-logo-title">
              <h3>Aplicativo</h3>

              <img
                className="logo-small"
                src="../assets/images/logo_maia.png"
              />
            </div>
            <div className="buttons-store">
              <a href="http://maiaapp.ai/" target="_blank">
                <img
                  className="app-store"
                  src="../assets/images/app_store.png"
                />
              </a>
              <a href="http://maiaapp.ai/" target="_blank">
                <img
                  className="android-store"
                  src="../assets/images/android_store.png"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="content-info col-lg-4 col-ml-4 col-sm-12">
              <div className="price-content">
                <p>Entrega do manual pelo Maia App</p>
                <a
                  onClick={() => {
                    handleOpenModal(5)
                  }}
                >
                  Saber mais sobre isso
                </a>
                <div className="container-price-month">
                  <h2>R$80,00</h2>
                  <span>/mês</span>
                </div>
              </div>
              <div className="selected-content">
                <input
                  name="properties"
                  type="checkbox"
                  className="check-custom toggle-switch"
                  checked={services.properties}
                />
                <span
                  className="check-toggle"
                  onClick={(e) => handleSelectServices('properties')}
                ></span>
              </div>
            </div>
            <div className="content-info sm-p-0 col-lg-4 col-ml-4 col-sm-12">
              <div className="price-content">
                <p>Plano de manutenção de Meu Imóvel</p>
                <a
                  onClick={() => {
                    handleOpenModal(6)
                  }}
                >
                  Saber mais sobre isso
                </a>
                <div className="container-price-month">
                  <h2>R$80,00</h2>
                  <span>/mês</span>
                </div>
              </div>
              <div className="selected-content">
                <input
                  name="maintenance"
                  type="checkbox"
                  className="check-custom toggle-switch"
                  checked={services.maintenance}
                />
                <span
                  className="check-toggle"
                  onClick={(e) => handleSelectServices('maintenance')}
                ></span>
              </div>
            </div>
            <div className="content-info col-lg-4 col-ml-4 col-sm-12">
              <div className="price-content">
                <p>Plano de Chamados Assistência Técnica</p>
                <a
                  onClick={() => {
                    handleOpenModal(7)
                  }}
                >
                  Saber mais sobre isso
                </a>
                <div className="container-price-month">
                  <h2>R$80,00</h2>
                  <span>/mês</span>
                </div>
              </div>
              <div className="selected-content">
                <input
                  name="assistance"
                  type="checkbox"
                  className="check-custom toggle-switch"
                  checked={services.assistance}
                />
                <span
                  className="check-toggle"
                  onClick={(e) => handleSelectServices('assistance')}
                ></span>
              </div>
            </div>
            <p>
              Para empreendimentos com até 100 unidades. Adquirindo todas as
              opções você <span>ganha 30% de desconto</span>
            </p>
          </div>
        </div>
        <div className="content box-4">
          <div className="header-content col-lg-3 col-ml-3 col-sm-12">
            <img src="../assets/images/confirmed-cuate.svg" className="img-2" />
          </div>
          <div className="content-info description col-lg-6 col-ml-6 col-sm-12">
            <h2>Você ganhou 15% desconto em nossos laudos</h2>
            <img src="../assets/images/logo_laudos.png" className="img-2" />
          </div>

          <div className="content-info justify-content-center col-lg-3 col-ml-3 col-sm-12">
            <p style={{ marginBottom: '2.35rem' }}>Pegar desconto</p>

            <input
              type="checkbox"
              className="check-custom toggle-switch"
              checked={services.discount}
            />
            <span
              className="check-toggle"
              onClick={(e) => handleSelectServices('discount')}
            ></span>
          </div>
        </div>
        <div className="upgrade-total">
          <div className="content-info total-content">
            <p>Subtotal</p>
            <h2>
              {parseFloat(total).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </h2>
            <button onClick={() => handleSubmit()}>Continuar</button>
            <a onClick={() => history.replace('/home')}>
              Continuar sem um upgrade
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
