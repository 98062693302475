import React, { useEffect, useState, createRef } from 'react'
import MaterialTable from 'material-table'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { tableIcons } from '../../../utils/TableIcons'
import { alertSuccess, alertError } from '../../../utils/Alert'

import api from '../../../services/api'

export default function ListUser({ handleEditUser }) {
  const tableRef = createRef()

  const handleConfirmDelete = (e, data, tableRef) => {
    Swal.fire({
      text: `Ter certeza que deseja excluir o usuário ${data.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteUser(e, data, tableRef)
      }
    })
  }

  const handleDeleteUser = async (e, data, tableRef) => {
    try {
      const response = await api.put(`/user/desative/${data.id}`)
      if (response.data.success) {
        alertSuccess(`Usuário ${data.name} deletado com sucesso!`)
        tableRef.current && tableRef.current.onQueryChange()
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="div-table">
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: 'Nome',
              field: 'name',
              type: 'string',
              filtering: false,
            },
            {
              title: 'E-mail',
              field: 'email',
              type: 'string',
              filtering: false,
            },
            {
              title: 'Data de cadastro',
              field: 'created_at',
              type: 'datetime',
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let field = ''
              if (query.orderBy !== undefined) field = query.orderBy.field
              api
                .get(
                  `/users?per_page=${query.pageSize}&page=${
                    query.page + 1
                  }&search=${query.search}&columnOrder=${field}&order=${
                    query.orderDirection
                  }`
                )
                .then((result) => {
                  resolve({
                    data: result.data.users,
                    page: query.page,
                    totalCount: result.data.total,
                  })
                })
            })
          }
          title="Listagem de Usuários"
          icons={tableIcons}
          actions={[
            {
              icon: tableIcons.Delete,
              tooltip: 'Deletar usuário',
              onClick: (event, rowData) => {
                handleConfirmDelete(event, rowData, tableRef)
              },
            },
            {
              icon: tableIcons.Edit,
              tooltip: 'Editar usuário',
              onClick: (event, rowData) => {
                handleEditUser(rowData)
              },
            },
            {
              icon: tableIcons.RefreshIcon,
              tooltip: 'Atualizar Tabela',
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
          options={{
            pageSize: 20,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página seguinte',
              lastTooltip: 'Última página',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum usuário encontrado',
            },
          }}
        />
      </div>
    </div>
  )
}
