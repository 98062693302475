import React from 'react'

export default function NotFound() {
  return (
    <div className="page-error">
      <div className="div-error">
        <h1>OOPS...</h1>
        <p>Pagina não encontrada !</p>
      </div>
      <div className="div-image-error">
        <img src="../assets/images/404-not-found.png" />
      </div>
    </div>
  )
}
