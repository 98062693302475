import React from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import useStyles from './style'

export default function Form({
  children,
  title,
  handleSubmit,
  titleButton,
  disable,
}) {
  const classes = useStyles()

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography className={classes.title} component="h3" variant="h5">
            {title}
          </Typography>
        </div>
        <div className={classes.form}>
          {children}
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
              disabled={disable}
              className={classes.button}
            >
              {titleButton}
            </Button>
          </div>
        </div>
      </Paper>
    </main>
  )
}
