import React, { useEffect, useState, createRef } from 'react'
import MaterialTable from 'material-table'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { tableIcons } from '../../../utils/TableIcons'
import { alertSuccess, alertError } from '../../../utils/Alert'

import api from '../../../services/api'

export default function ListResend() {
  const history = useHistory()
  const tableRef = createRef()

  const handleCheckValid = (e, data, tableRef) => {
    Swal.fire({
      text: `Ter certeza que deseja validar ${data.title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, validar!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleValidManual(e, data, tableRef)
      }
    })
  }

  const handleCheckNotValid = (e, data, tableRef) => {
    Swal.fire({
      text: `Ter certeza que deseja recusar o ${data.title}? Mensagem Usuário: ${data.justification}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, recusar!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Qual a justificativa?',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          preConfirm: (justification) => {
            handleNotValidManual(e, data, tableRef, justification)
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
      }
    })
  }

  const handleValidManual = async (e, data, tableRef) => {
    try {
      const response = await api.put(`/valid/${data.id}`)
      if (response.data.success) {
        alertSuccess(response.data.success)
        tableRef.current && tableRef.current.onQueryChange()
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleNotValidManual = async (e, data, tableRef, justification) => {
    try {
      const response = await api.put(`/not-valid/${data.id}`, { justification })
      if (response.data.success) {
        alertSuccess(response.data.success)
        tableRef.current && tableRef.current.onQueryChange()
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleEditManual = async (e, data) => {
    try {
      localStorage.setItem('@BV3/dataManual', JSON.stringify(data))

      history.push('/dashboard/manuals/edit')
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleViewManual = async (e, data) => {
    let newTitle = data.title.replace(/ /g, '&&').replace(/\//g, '&c')
    const win = window.open(`/manual/${data.id}/${newTitle}`, '_blank')
    win.focus()
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="div-table">
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: 'Manual',
              field: 'title',
              type: 'string',
              filtering: false,
            },
            {
              title: 'Nome',
              field: 'name',
              type: 'string',
              filtering: false,
            },
            {
              title: 'Reenviado',
              field: '',
              type: 'string',
              align: 'center',
              filtering: false,
              render: (rowData) =>
                rowData.is_resend == 1 ? (
                  <div className="div-circle-status">
                    <div id="circle-green" />
                  </div>
                ) : (
                  <div className="div-circle-status">
                    <div id="circle-grey" />
                  </div>
                ),
            },
            {
              title: 'Data de cadastro',
              field: 'created_at',
              type: 'datetime',
              defaultSort: 'desc',
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let field = ''
              if (query.orderBy !== undefined) field = query.orderBy.field
              api
                .get(
                  `/resend/manuals?valid=false&per_page=${
                    query.pageSize
                  }&page=${query.page + 1}&search=${
                    query.search
                  }&columnOrder=${field}&order=${query.orderDirection}`
                )
                .then((result) => {
                  resolve({
                    data: result.data.manuals,
                    page: query.page,
                    totalCount: result.data.total,
                  })
                })
            })
          }
          title="Listagem de Manuais"
          icons={tableIcons}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: 'Editar Manual',
              onClick: (event, rowData) => {
                handleEditManual(event, rowData)
              },
            },
            {
              icon: tableIcons.ListIcon,
              tooltip: 'Visualizar Manual',
              onClick: (event, rowData) => {
                handleViewManual(event, rowData)
              },
            },
            {
              icon: tableIcons.Check,
              tooltip: 'Validar Manual',
              onClick: (event, rowData) => {
                handleCheckValid(event, rowData, tableRef)
              },
            },
            {
              icon: tableIcons.Block,
              tooltip: 'Recusar Manual',
              onClick: (event, rowData) => {
                handleCheckNotValid(event, rowData, tableRef)
              },
            },
            {
              icon: tableIcons.RefreshIcon,
              tooltip: 'Atualizar Tabela',
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
          options={{ pageSize: 20 }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página seguinte',
              lastTooltip: 'Última página',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum Manual encontrado',
            },
          }}
        />
      </div>
    </div>
  )
}
