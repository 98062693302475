import Routes from './Routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FilterProvider } from './hooks/useFilter'
import { PercentageProvider } from './hooks/usePercentage'

function App() {
  return (
    <div className="App">
      <PercentageProvider>
        <FilterProvider>
          <Routes />
          <ToastContainer />
        </FilterProvider>
      </PercentageProvider>
    </div>
  )
}

export default App
