import React from 'react'

export default function AccessRestricted() {
  return (
    <div className="page-error">
      <div className="div-error">
        <h1>ACESSO NEGADO</h1>
        <p>Você não tem acesso à essa pagina !</p>
      </div>
      <div className="div-image-error">
        <img src="../assets/images/403-security.png" />
      </div>
    </div>
  )
}
