import styled from 'styled-components'

export const Container = styled.div`
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 1.5rem;
  display: flex;
  align-self: center;
  justify-content: center;
`
export const Text = styled.text`
  font-size: clamp(1rem, 3vw, 2.25rem);
  text-align: center;
  margin-bottom: 0.31rem;
  font-family: Montserrat-bold;
  color: black;
  justify-content: center;
  align-self: center;
`
