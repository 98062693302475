import React from 'react'

// type PaginationItemProps = {
//   number: number,
//   isCurrent?: boolean,
//   onPageChange: (page: number) => void,
// }

const PaginationItem = ({ number, isCurrent, onPageChange }) => {
  return (
    <button
      onClick={() => {
        if (!isCurrent) onPageChange(number)
      }}
      style={{
        color: 'white',
        backgroundColor: !isCurrent ? 'gray' : '',
        width: '0.75rem',
        margin: '0 0.25rem',
      }}
      disabled={isCurrent}
    >
      {number}
    </button>
  )
}

export default PaginationItem
