import React, { useState, useEffect } from 'react'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import api from '../../../services/api'

import { QuestionTypes, OrderList } from '../../../utils/Question.json'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import useStyles from './style'

export default function FormQuestion({
  tituloSumario,
  setTituloSumario,
  question,
  setQuestion,
  alternatives,
  setAlternatives,
  type,
  edit,
  setType,
  selected,
  setSelected,
}) {
  const [selectCategory, setSelectCategory] = useState([])
  const [listModules, setListModules] = useState([])
  const [listPosition, setListPosition] = useState([])
  const [button, setButton] = useState(false)
  const loadCategory = async () => {
    let response = await api.get(
      `/categories?columnOrder=categorie&order=asc&search=`
    )
    setSelectCategory(response.data.categories)
  }
  // const loadPosition = async () => {
  //   let response = await api.get(`/positions`)
  //   setListPosition(response.data.position)
  // }
  // const loadPosition = async (categorie_id, index) => {
  //   // setListPosition(response.data.position)
  //   // //console.log('cat', categorie_id)
  //   // //console.log('sel', selected[index])
  // }
  useEffect(() => {
    setTimeout(() => setButton(true), 3000)
  }, [])

  useEffect(() => {
    if (edit === true) {
      // console.log('selected aqui', selected)
      selected.map(async (select, index) => {
        let response = await api.get(
          `/positions/${selected[index].categorie_id}/${selected[index].modules_id}`
        )
        // console.log(response.data)
        listPosition[index] = response.data

        const listPositionSet = listPosition.map((ArrayOfPositions) => {
          const positionSet = new Set()
          ArrayOfPositions.forEach((positionObject) => {
            positionSet.add(positionObject.position)
          })
          // console.log(Array.from(positionSet))
          return Array.from(positionSet)
        })

        const listPositionArray = listPositionSet.map((ArrayOfPositions) => {
          return ArrayOfPositions.map((posicao) => {
            return { position: posicao }
          })
        })
        // console.log(listPositionArray)

        // setListPosition([...listPositionArray])
        setListPosition([...listPosition])
      })
    }
  }, [selected])

  useEffect(() => {
    loadCategory()
    if (edit === false) {
      setSelected([{ categorie_id: '', modules_id: '', position: '' }])
    }
  }, [])

  useEffect(() => {
    if (edit === true) {
      selected.forEach((item, index) => {
        loadModules(item.categorie_id, index)
      })
    }
  }, [selected])

  const [selectModules, setSelectModules] = useState([])

  const loadModules = async (categorie_id, index) => {
    if (categorie_id) {
      let response = await api.get(
        `/modules?columnOrder=&order=&search=&filters=${JSON.stringify([
          categorie_id,
        ])}`
      )
      // listModules.push(response.data.modules)
      listModules[index] = response.data.modules
      setListModules([...listModules])
    }
  }

  let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ]

  const classes = useStyles()

  const handleChangeAlternative = async (e, index, inputName = null) => {
    // console.log('aaaa', e)
    const name = inputName ? inputName : e.target.name
    const value = e.target ? e.target.value : e
    // console.log('value', value)

    alternatives[index] = {
      ...alternatives[index],
      [name]: value,
    }

    setAlternatives([...alternatives])
  }

  const handleChange = (event, index) => {
    // let aux = selected
    // aux[index].categorie_id = event.target.value
    // setSelected(aux)

    selected[index] = {
      ...selected[index],
      categorie_id: event.target.value,
    }
    loadModules(event.target.value, index)

    setSelected([...selected])

    // const newArray = selectCategory.splice(index, 1)
    // setSelectCategory(selectCategory)
    // console.log(newArray)
  }
  const handleChangeModules = async (event, index) => {
    // let aux = selected
    // aux[index].modules_id = event.target.value
    // setSelected(aux)
    let response = await api.get(
      `/positions/${selected[index].categorie_id}/${event.target.value}`
    )
    selected[index] = {
      ...selected[index],
      modules_id: event.target.value,
      // positions: response.data,
    }
    listPosition[index] = response.data
    setListPosition([...listPosition])
    // //console.log('sel', selected[index])

    setSelected([...selected])
  }
  const handleChangePosition = (event, index) => {
    // let aux = selected
    // aux[index].modules_id = event.target.value
    // setSelected(aux)
    selected[index] = {
      ...selected[index],
      position: event.target.value,
    }

    // console.log(selected)
    setSelected([...selected])
  }

  const handleAddInputs = (e) => {
    e.preventDefault()

    if (
      alternatives[alternatives.length - 1].alternative === '' ||
      alternatives[alternatives.length - 1].alternative_text === ''
    ) {
      alertInfo(
        'Preencha os campos vazio para poder adicionar mais alternativas!'
      )
      return
    }

    for (let [key, value] of Object.entries(alternatives[0])) {
      if (key != 'id')
        var newObject = {
          ...newObject,
          [key]: '',
        }
    }

    setAlternatives([...alternatives, newObject])
  }
  const handleAddSelect = (e) => {
    e.preventDefault()

    if (
      selected[selected.length - 1].categorie_id === '' ||
      selected[selected.length - 1].modules_id === ''
    ) {
      alertInfo(
        'Preencha os campos vazio para poder adicionar mais categorias de manuais ou módulos!'
      )
      return
    }

    for (let [key, value] of Object.entries(selected[0])) {
      if (key != 'id')
        var newObject = {
          ...newObject,
          [key]: '',
        }
    }

    setSelected([...selected, newObject])
  }

  const handleRemoveInputs = (e, position) => {
    e.preventDefault()
    setAlternatives([...alternatives.filter((_, index) => index !== position)])
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
        <TextField
          variant="outlined"
          multiline
          required
          id="question"
          name="question"
          label="Questão:"
          fullWidth
          onChange={(e) => setQuestion(e.target.value)}
          value={question}
        />
      </Grid>
      <Grid item xs={12} sm={3} marginbottom={2}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel id="demo-simple-select-outlined-label">Tipo:</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Tipo"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            {QuestionTypes.map((type) => (
              <MenuItem key={type.key} value={type.key}>
                {type.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          style={{ width: '100%' }}
          id="outlined-basic"
          value={tituloSumario}
          onChange={(event) => setTituloSumario(event.target.value)}
          label="Título Sumário"
          variant="outlined"
        />
      </Grid>
      {selected.map((item, index) => (
        <>
          <Grid item xs={12} sm={6} marginbottom={3}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="categorie">Categoria de manuais:</InputLabel>
              <Select
                fullWidth
                labelId="categorie"
                id="demo-simple-select"
                label="Categoria de manuais:"
                value={item.categorie_id}
                onChange={(e) => handleChange(e, index)}
              >
                {selectCategory.map((category) => (
                  <MenuItem value={category.id}>{category.categorie}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} marginbottom={2}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="modules">Módulos:</InputLabel>
              <Select
                fullWidth
                id="demo-simple-select-modules"
                labelId="modules"
                label="Módulos"
                value={item.modules_id}
                onChange={(e) => handleChangeModules(e, index)}
              >
                {listModules[index]?.map((modules) => (
                  <MenuItem value={modules.id}>
                    <div
                      style={{
                        whiteSpace: 'normal',
                      }}
                    >
                      {modules.module}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            {!edit ? (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="position">Posição:</InputLabel>
                <Select
                  fullWidth
                  id="demo-simple-select-modules"
                  labelId="posicao"
                  label="Posição"
                  value={item.position}
                  onChange={(e) => handleChangePosition(e, index)}
                >
                  <MenuItem value={1}>{1}</MenuItem>
                  {listPosition[index] &&
                    listPosition[index].map((positions) => (
                      <MenuItem value={Number(positions.position) + 1}>
                        {Number(positions.position) + 1}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="position">Posição:</InputLabel>
                <Select
                  fullWidth
                  id="demo-simple-select-modules"
                  labelId="posicao"
                  label="Posição"
                  value={item.position}
                  onChange={(e) => handleChangePosition(e, index)}
                >
                  {listPosition[index] && listPosition[index].length > 0 ? (
                    listPosition[index].map((positions) => (
                      <MenuItem value={Number(positions.position)}>
                        {Number(positions.position)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={1}>1</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>

          <Divider
            style={{
              height: 2,
              width: '100%',
              color: 'rgb(0,0,0)',
            }}
          />
        </>
      ))}
      <Grid item xs={10}>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            className={classes.buttonAddInput}
            startIcon={<AddIcon />}
            onClick={(e) => handleAddSelect(e)}
          ></Button>
        </Grid>
      </Grid>
      {(type === 1 || type === 2) &&
        alternatives.map((alternative, index) => (
          <fieldset key={index} className="groupbox-border">
            {/* {console.log('alternatives1', alternatives)} */}
            {/* {//console.log(`alternativa ${index}`, alternative)} */}
            <legend className="groupbox-border">
              Alternativa {OrderList[index]}
            </legend>
            <div className={classes.divIcon}>
              {alternatives.length !== 1 && (
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleRemoveInputs(e, index)}
                  className={classes.buttonIcon}
                >
                  <DeleteForeverIcon className={classes.icon} />
                </IconButton>
              )}
            </div>
            <Grid item xs={12} sm={11} className={classes.text}>
              <TextField
                id="question"
                multiline
                name="alternative"
                label={OrderList[index] + '.'}
                value={alternative.alternative}
                onChange={(e) => handleChangeAlternative(e, index)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              {/* <TextField
                multiline
                id="question"
                name="alternative_text"
                label={`Texto`}
                value={alternatives[index].alternative_text}
                onChange={(e) => handleChangeAlternative(e, index)}
                fullWidth
              /> */}
              <ReactQuill
                theme="snow"
                multiline
                id="question"
                name="alternative_text"
                value={alternative.alternative_text}
                onChange={(e) => {
                  if (button)
                    handleChangeAlternative(e, index, 'alternative_text')
                  // console.log('content', content)
                  // console.log('delta', delta)
                  // console.log('source', source)
                  // console.log('editor', editor)
                }}
                placeholder={'Digite o Texto'}
                modules={{ toolbar: toolbarOptions }}
              />
            </Grid>
          </fieldset>
        ))}
      {(type === 1 || type === 2) && (
        <Grid item xs={12}>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              className={classes.buttonAddInput}
              startIcon={<AddIcon />}
              onClick={(e) => handleAddInputs(e)}
            >
              Novo
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
