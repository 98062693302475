import React from 'react'
import Flex from '../Flex'
// import { Stack, Button, Box } from "@chakra-ui/react";
import PaginationItem from './PaginationItem'

// type PaginationProps = {
//   totalCountOfRegister: number;
//   registerPerPage?: number;
//   currentPage?: number;
//   lastPage: number;
//   onPageChange: (page: number) => void;
// };

const Pagination = ({
  totalCountOfRegister,
  registerPerPage = 10,
  currentPage = 1,
  onPageChange,
}) => {
  const lastPage = Math.ceil(totalCountOfRegister / registerPerPage)
  // console.log(totalCountOfRegister);
  // console.log(registerPerPage);
  // console.log(Math.ceil(totalCountOfRegister / registerPerPage));
  // console.log(Math.floor(totalCountOfRegister / registerPerPage));

  const siblingsCount = 1

  const generatePagesArray = (from, to) => {
    return [...new Array(to - from)]
      .map((_, index) => {
        return from + index + 1
      })
      .filter((page) => page > 0)
  }

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <div style={{ marginRight: '1rem' }}>
        <strong>{(currentPage - 1) * registerPerPage}</strong> -{' '}
        <strong>
          {registerPerPage * currentPage > totalCountOfRegister
            ? totalCountOfRegister
            : registerPerPage * currentPage}
        </strong>{' '}
        de <strong>{totalCountOfRegister}</strong>
      </div>
      <Flex alignItems="center">
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem onPageChange={onPageChange} number={1} />
            {currentPage > 2 + siblingsCount && <p>...</p>}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={onPageChange}
                key={page}
                number={page}
              />
            )
          })}

        <PaginationItem
          onPageChange={onPageChange}
          number={currentPage}
          isCurrent
        />

        {nextPages.length > 0 &&
          nextPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={onPageChange}
                key={page}
                number={page}
              />
            )
          })}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && <p>...</p>}
            <PaginationItem onPageChange={onPageChange} number={lastPage} />
          </>
        )}
      </Flex>
    </div>
  )
}

export default Pagination
