import React, { useEffect, useState, createRef } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { tableIcons } from '../../../utils/TableIcons'
import { alertSuccess, alertError } from '../../../utils/Alert'
import { useFilter } from '../../../hooks/useFilter'
import Button from '@material-ui/core/Button'
import api from '../../../services/api'
import './styles.css'

export default function ListQuestion() {
  const tableRef = createRef()
  const history = useHistory()

  const {
    defaultFilterTipos,
    defaultFilterCategorias,
    defaultFilterModulos,
    setFiltrosTipos,
    setFiltrosCategorias,
    setFiltrosModulos,
  } = useFilter()

  const [lookUpCategories, setLookupCategories] = useState({})
  const [lookUpModules, setLookupModules] = useState({})
  const [tableData, setTableData] = useState([])

  const Toolbar = (props) => {
    const { tableRef } = props
    return (
      <div style={{ dislay: 'flex', width: '100%' }}>
        <MTableToolbar {...props} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{
              backgroundColor: '#23394a',
              color: 'white',
              marginRight: '8px',
            }}
            variant="contained"
            onClick={saveFilters(tableRef)}
          >
            Salvar Filtros
          </Button>

          <Button
            style={{ backgroundColor: '#23394a', color: 'white' }}
            variant="contained"
            onClick={() => eraseFilters()}
          >
            Apagar Filtros
          </Button>
        </div>
      </div>
    )
  }

  // Pega todas as categorias de manuais.
  const getLookupManuals = async () => {
    try {
      const response = await api.get('/categories')
      const lookupFormatado = {}

      response.data.categories.forEach((categoria) => {
        lookupFormatado[String(categoria.id)] = categoria.categorie
      })
      console.log(lookupFormatado)
      setLookupCategories(lookupFormatado)
    } catch (error) {
      console.log(error)
    }
  }

  // Pega todos os módulos.
  const getLookupModules = async () => {
    try {
      const response = await api.get('/modulos')
      const lookupFormatado = {}

      response.data.forEach((modulo) => {
        // lookupFormatado[modulo.module] = modulo.module
        lookupFormatado[String(modulo.id)] = modulo.module
      })

      console.log(lookupFormatado)
      setLookupModules(lookupFormatado)
    } catch (error) {
      console.log(error)
    }
  }

  function formataQuestoes(
    questoes,
    lookupFormatadoCategorias,
    lookupFormatadoModulos
  ) {
    const questoes_formatadas = questoes?.map((questao) => {
      return {
        ...questao,
        posicoes: questao.posicoes.join(', '),
        categoria: questao.presenteNosManuaisDeId
          .map((id) => lookupFormatadoCategorias[id])
          .join(', '),
        modulo: questao.presenteNosModulosDeId
          .map((id) => lookupFormatadoModulos[id])
          .join(', '),
      }
    })
    console.log(lookupFormatadoCategorias, lookupFormatadoModulos)
    return questoes_formatadas
  }

  useEffect(() => {
    getLookupManuals()
    getLookupModules()
  }, [])

  // useEffect(() => {
  //   getTableData()
  // }, [lookUpCategories, lookUpModules])

  // useEffect(() => {
  //   getTableData()
  // }, [])

  const handleConfirmDelete = (e, data, tableRef) => {
    Swal.fire({
      text: `Ter certeza que deseja excluir a questão '${data.question}'`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteQuestion(e, data, tableRef)
      }
    })
  }

  const handleDeleteQuestion = async (e, data, tableRef) => {
    try {
      const response = await api.delete(`/questions/${data.id}`)

      if (response.data.success) {
        alertSuccess(`Questão deletada com sucesso!`)
        tableRef.current && tableRef.current.onQueryChange()
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleEdit = async (e, data, tableRef) => {
    localStorage.setItem('@BV3/idQuestionSelected', data.id)
    history.push('/dashboard/question/edit')
  }

  function saveFilters(tableRef) {
    return function handler() {
      const columns = tableRef?.current?.state.columns.map((column) => ({
        field: column.field,
        filterValue: column.tableData.filterValue,
      }))
      console.log(tableRef.current.state)
      console.log(columns, null, 2)

      const filtroTipo =
        columns.find((filtro) => filtro.field === 'type_id')?.filterValue || []

      const filtroCategoria =
        columns.find((filtro) => filtro.field === 'categorie_id')
          ?.filterValue || []

      const filtroModulo =
        columns.find((filtro) => filtro.field === 'modules_id')?.filterValue ||
        []

      console.log(filtroTipo, filtroCategoria, filtroModulo)

      setFiltrosTipos(filtroTipo)
      setFiltrosCategorias(filtroCategoria)
      setFiltrosModulos(filtroModulo)
    }
  }

  const eraseFilters = () => {
    setFiltrosTipos([])
    setFiltrosCategorias([])
    setFiltrosModulos([])
  }

  const lookupTiposDeAlternativa = {
    1: 'Alternativa',
    2: 'Múltiplas Alternativas',
    3: 'Texto',
    4: 'Imagem',
  }

  const columns = [
    {
      title: 'Questão',
      field: 'question',
      type: 'string',
      filtering: false,
    },
    {
      title: 'Tipo',
      field: 'type_id',
      type: 'string',
      lookup: lookupTiposDeAlternativa,
      defaultFilter: defaultFilterTipos,
    },
    {
      title: 'Categoria de manuais',
      field: 'categorie_id',
      type: 'string',
      lookup: lookUpCategories,
      defaultFilter: defaultFilterCategorias,

      render: (rowData) => {
        // {
        //   console.log(rowData)
        // }
        return <p>{rowData.categoria}</p>
      },
    },
    {
      title: 'Módulos',
      field: 'modules_id',
      type: 'string',
      lookup: lookUpModules,
      defaultFilter: defaultFilterModulos,

      render: (rowData) => <p>{rowData.modulo}</p>,
    },
    {
      title: 'Posição',
      field: 'posicoes',
      type: 'string',
      render: (rowData) => {
        return <p>{rowData.posicoes}</p>
      },
      filtering: false,
      sorting: false,
      draggable: false,
    },
    {
      title: 'Data de criação',
      field: 'created_at',
      type: 'datetime',
      filtering: false,
    },
  ]

  const actions = [
    {
      icon: tableIcons.Delete,
      tooltip: 'Deletar questão',
      onClick: (event, rowData) => {
        handleConfirmDelete(event, rowData, tableRef)
      },
    },
    {
      icon: tableIcons.Edit,
      tooltip: 'Editar questão',
      onClick: (event, rowData) => {
        handleEdit(event, rowData, tableRef)
      },
    },
    {
      icon: tableIcons.RefreshIcon,
      tooltip: 'Atualizar Tabela',
      isFreeAction: true,
      onClick: () => tableRef.current && tableRef.current.onQueryChange(),
    },
  ]

  return (
    <div className="d-flex justify-content-center">
      <div className="div-table">
        <MaterialTable
          components={{
            Toolbar: (props) => <Toolbar tableRef={tableRef} {...props} />,
          }}
          tableRef={tableRef}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              let field = ''
              let filters = encodeURIComponent(JSON.stringify([]))
              let categories = encodeURIComponent(JSON.stringify([]))
              let modules = encodeURIComponent(JSON.stringify([]))

              if (query.orderBy !== undefined) field = query.orderBy.field
              for (let i = 0; i < query.filters.length; i++) {
                if (query.filters[i].column.field == 'type_id') {
                  filters = encodeURIComponent(
                    JSON.stringify(query.filters[i].value)
                  )
                }
                if (query.filters[i].column.field == 'categorie_id') {
                  categories = query.filters[i].value

                  categories = encodeURIComponent(
                    JSON.stringify(query.filters[i].value)
                  )
                }
                if (query.filters[i].column.field == 'modules_id') {
                  modules = encodeURIComponent(
                    JSON.stringify(query.filters[i].value)
                  )
                }
              }

              let lookupFormatadoCategorias = {}
              let lookupFormatadoModulos = {}

              const promise1 = api.get('/categories').then((response) => {
                response.data.categories.forEach((categoria) => {
                  lookupFormatadoCategorias[String(categoria.id)] =
                    categoria.categorie
                })
                // setLookupCategories(lookupFormatadoCategorias)
              })

              const promise2 = api.get('/modulos').then((response) => {
                response.data.forEach((modulo) => {
                  // lookupFormatado[modulo.module] = modulo.module
                  lookupFormatadoModulos[String(modulo.id)] = modulo.module
                })

                // setLookupModules(lookupFormatadoModulos)
              })
              Promise.all([promise1, promise2]).then((result) => {
                api
                  .get(
                    `/questions?per_page=${query.pageSize}&page=${
                      query.page + 1
                    }&search=${query.search}&columnOrder=${field}&order=${
                      query.orderDirection
                    }&filters=${filters}&categorie_id=${categories}&module_id=${modules}`
                  )
                  .then((result) => {
                    console.log(
                      'aaa',
                      lookupFormatadoCategorias,
                      lookupFormatadoModulos
                    )
                    const a = formataQuestoes(
                      result.data.retornoQuestoes,
                      lookupFormatadoCategorias,
                      lookupFormatadoModulos
                    )
                    console.log(a)
                    resolve({
                      data: a,
                      page: query.page,
                      totalCount: result.data.total,
                    })
                  })
              })
            })
          }
          title="Listagem de Questões"
          icons={tableIcons}
          actions={actions}
          options={{
            filtering: true,
            pageSize: 20,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página seguinte',
              lastTooltip: 'Última página',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhuma Questão encontrado',
            },
          }}
        />
      </div>
    </div>
  )
}
