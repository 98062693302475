export const getToken = () => localStorage.getItem('@BV3/jwt')
export const getUser = () => JSON.parse(localStorage.getItem('@BV3/dataUser'))
export const login = (token, user, access) => {
  localStorage.setItem('@BV3/jwt', token)
  localStorage.setItem('@BV3/dataUser', JSON.stringify(user))
  localStorage.setItem('@BV3/access', access)
}
export const logout = () => {
  localStorage.removeItem('@BV3/jwt')
  localStorage.removeItem('@BV3/access')
  localStorage.removeItem('@BV3/dataUser')
}

export const isAuthenticated = () => localStorage.getItem('@BV3/jwt') !== null
