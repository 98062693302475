import React, { useEffect, useState, createRef } from 'react'
import MaterialTable from 'material-table'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'

import useStyles from './style'

import { tableIcons } from '../../../utils/TableIcons'
import { alertSuccess, alertError } from '../../../utils/Alert'

import api from '../../../services/api'

export default function ListModule({
  handleEditModule,
  edit,
  setCategorie_id,
  categorie_id,
  modules,
  setModules,
}) {
  const tableRef = createRef()

  const [selectCategory, setSelectCategory] = useState([])
  const classes = useStyles()
  const [disable, setDisable] = useState(false)
  const loadCategory = async () => {
    let response = await api.get(
      `/categories?per_page=10&page=1&columnOrder=categorie&order=asc&search=`
    )
    let obj_lookup = {}

    response.data.categories.map((item) => {
      obj_lookup[item.id] = item.categorie
    })

    setSelectCategory(obj_lookup)
  }
  useEffect(() => {
    loadCategory()
    if (edit === false) {
      setCategorie_id('')
    }
  }, [])
  const handleConfirmDelete = (e, data, tableRef) => {
    Swal.fire({
      text: `Ter certeza que deseja excluir o ${data.module}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteModule(e, data, tableRef)
      }
    })
  }

  const handleDeleteModule = async (e, data, tableRef) => {
    try {
      // const response = await api.delete(`/modules/${data.id}`)
      const response = await api.delete(`/modules/${data.module}`, {
        moduleName: modules,
      })

      if (response.data.success) {
        alertSuccess(`Módulo ${data.module} deletado com sucesso!`)
        tableRef.current && tableRef.current.onQueryChange()
      }
    } catch (response) {
      alertError(response.data.error)
    }
  }
  return (
    <div className="d-flex justify-content-center">
      <div className="div-table">
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: 'Módulo',
              field: 'module',
              type: 'string',
              filtering: false,
            },
            {
              title: 'Categoria de manuais',
              field: 'categorieid',
              lookup: selectCategory,
            },
            {
              title: 'Data de cadastro',
              field: 'created_at',
              type: 'datetime',
              filtering: false,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let field = ''
              let filters = encodeURIComponent(JSON.stringify([]))
              if (query.orderBy !== undefined) field = query.orderBy.field
              if (query.filters.length > 0)
                filters = encodeURIComponent(
                  JSON.stringify(query.filters[0].value)
                )

              api
                .get(
                  `/modules?per_page=${query.pageSize}&page=${
                    query.page + 1
                  }&search=${query.search}&columnOrder=${field}&order=${
                    query.orderDirection
                  }&filters=${filters}`
                )
                .then((result) => {
                  resolve({
                    data: result.data.modules,
                    page: query.page,
                    totalCount: result.data.total,
                  })
                })
            })
          }
          title="Listagem de Módulos"
          icons={tableIcons}
          actions={[
            {
              icon: tableIcons.Delete,
              tooltip: 'Deletar módulo',
              onClick: (event, rowData) => {
                handleConfirmDelete(event, rowData, tableRef)
              },
            },
            {
              icon: tableIcons.Edit,
              tooltip: 'Editar módulo',
              onClick: (event, rowData) => {
                handleEditModule(rowData)
              },
            },
            {
              icon: tableIcons.RefreshIcon,
              tooltip: 'Atualizar Tabela',
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
          options={{ filtering: true, pageSize: 20 }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página seguinte',
              lastTooltip: 'Última página',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum usuário encontrado',
            },
          }}
        />
      </div>
    </div>
  )
}
