import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import { QuestionTypes, OrderList } from '../../../utils/Question.json'
import { checkNulls } from '../../../utils/CheckNulls'
import api from '../../../services/api'

import Form from '../../../Components/Form'
import FormQuestion from '../../Layouts/FormQuestion'

export default function EditQuestion() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [question, setQuestion] = useState('')
  const [tituloSumario, setTituloSumario] = useState('')
  const [type, setType] = useState('')
  const [alternatives, setAlternatives] = useState([
    { alternative: '', alternative_text: '' },
  ])
  const [selected, setSelected] = useState([
    { categorie_id: '', modules_id: '', position: '' },
  ])

  useEffect(() => {
    handleChangeData()
  }, [])

  const handleChangeData = async () => {
    try {
      setLoading(true)
      const id = localStorage.getItem('@BV3/idQuestionSelected')
      const response = await api.get(`/questions/${id}`)
      console.log(response.data)
      setQuestion(response.data.question.question)
      setTituloSumario(response.data.question.titulo_sumario)
      setType(response.data.question.type_id)
      // console.log('z', response.data)
      // console.log('id', id)
      if (response.data.question.alternatives)
        setAlternatives(response.data.question.alternatives)
      if (response.data.questions_categories_modules.length > 0) {
        console.log(response.data.questions_categories_modules)
        setSelected(response.data.questions_categories_modules)
      }
      setLoading(false)
    } catch (response) {
      setLoading(false)
      alertError(response.data.error)
    }
  }

  const handleSubmit = async (e) => {
    try {
      setDisable(true)
      setLoading(true)
      const id = localStorage.getItem('@BV3/idQuestionSelected')

      if (type === '' || question === '') {
        alertInfo('Por favor, preencha os campos vazio!')
        setLoading(false)
        setDisable(false)
        return
      }

      let response
      console.log('a', selected)
      if (type === 3 || type === 4) {
        response = await api.put(`/questions/${id}`, {
          question,
          type_id: type,
          questions_categories_modules: selected,
          titulo_sumario: tituloSumario,
        })
      } else {
        const existeNull = await checkNulls(alternatives)
        if (existeNull === true) {
          alertInfo('Por favor, preencha todas as alternativas!')
          setLoading(false)
          setDisable(false)
          return
        }
        response = await api.put(`/questions/${id}`, {
          question,
          type_id: type,
          alternatives,
          titulo_sumario: tituloSumario,
          questions_categories_modules: selected,
        })
      }

      if (response.data.success) {
        setDisable(false)
        setLoading(false)
        alertSuccess(response.data.success)
        history.replace('/dashboard/question')
      }
    } catch (response) {
      setLoading(false)
      setDisable(false)
      alertError(response.data.error)
    }
  }
  return (
    <>
      {loading == true && (
        <div className="loading-dashboard">
          <CircularProgress />
        </div>
      )}
      <Form
        title={'Cadastro das Questões'}
        handleSubmit={handleSubmit}
        titleButton={'Atualizar'}
        disable={disable}
      >
        <FormQuestion
          tituloSumario={tituloSumario}
          setTituloSumario={setTituloSumario}
          question={question}
          setQuestion={setQuestion}
          type={type}
          setType={setType}
          alternatives={alternatives}
          setAlternatives={setAlternatives}
          selected={selected}
          setSelected={setSelected}
          edit={true}
        />
      </Form>
    </>
  )
}
