import React from 'react'
import { url } from '../../../services/api'

import TextField from '@material-ui/core/TextField'

export default function FirstPage({ title, setTitle, cover, setCover }) {
  const handleUpload = async (files) => {
    const uploadedFiles = {
      file: files[0],
      name: files[0].name,
      preview: URL.createObjectURL(files[0]),
    }

    await setCover(uploadedFiles)
  }
  return (
    <>
      <form className="first-form-question">
        <div>
          <div className="body-first-question title">
            <p>Qual será o nome do seu Manual?</p>
            <TextField
              className="manual-name"
              multiline
              id="obs"
              name="obs"
              label=""
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </div>
        </div>
      </form>
      <div className="row manual-proposal">
        <div className="col-lg-4 d-flex justify-content-center align-items-start">
          <img
            className="cover-manual-proposal"
            src="../assets/images/cover-proposal1.png"
            onClick={() => setCover(1)}
            style={{
              borderWidth: cover == 1 ? 2 : 0,

              boxShadow: cover == 1 && '9px 7px 5px rgba(50, 50, 50, 0.77)',
            }}
          />
        </div>
        <div className="col-lg-4 d-flex justify-content-center align-items-start">
          <img
            className="cover-manual-proposal"
            src="../assets/images/cover-proposal2.png"
            onClick={() => setCover(2)}
            style={{
              borderWidth: cover == 2 ? 2 : 0,

              boxShadow: cover == 2 && '9px 7px 5px rgba(50, 50, 50, 0.77)',
            }}
          />
        </div>
        <div className="col-lg-4 d-flex justify-content-center align-items-start">
          <img
            className="cover-manual-proposal"
            src="../assets/images/cover-proposal3.png"
            onClick={() => setCover(3)}
            style={{
              borderWidth: cover == 3 ? 2 : 0,

              boxShadow: cover == 3 && '9px 7px 5px rgba(50, 50, 50, 0.77)',
            }}
          />
        </div>
      </div>
      <div className="upload-container">
        <div id="vertical-line"></div>
        <input
          style={{ opacity: '0', width: '0', height: '0' }}
          type="file"
          id="cover-file"
          name="cover-file"
          accept="image/*"
          onChange={(e) => handleUpload(e.target.files)}
        />

        <label for="cover-file">Selecione uma imagem do seu computador</label>
        <div id="vertical-line"></div>
      </div>
      {cover.preview ? (
        <div className="d-flex justify-content-center mt-5 mb-3">
          <img className="cover-manual-proposal" src={cover.preview} />
        </div>
      ) : (
        cover != 1 &&
        cover != 2 &&
        cover != 3 &&
        cover != '' && (
          <div className="d-flex justify-content-center mt-5 mb-3">
            <img className="cover-manual-proposal" src={url + cover} />
          </div>
        )
      )}
    </>
  )
}
