import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'

import { alertError, alertInfo, alertSuccess } from '../../../utils/Alert'
import { checkNulls } from '../../../utils/CheckNulls'
import api from '../../../services/api'
import TextField from '@material-ui/core/TextField'

import Form from '../../../Components/Form'

export default function EditCategorie({ match }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [categorie, setCategorie] = useState('')

  useEffect(() => {
    handleChangeData()
  }, [])

  const handleChangeData = async () => {
    try {
      const id = match.params.id
      setLoading(true)
      const response = await api.get(`/categories/${id}`)

      setCategorie(response.data.categories[0].categorie)
      setLoading(false)
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleSubmit = async (e) => {
    try {
      setDisable(true)
      setLoading(true)
      const id = match.params.id

      if (categorie === '') {
        alertInfo('Por favor, preencha os campos vazio!')
        setLoading(false)
        setDisable(false)
        return
      }
      const response = await api.put(`/categories/${id}`, { categorie })

      if (response.data.success) {
        setDisable(false)
        setLoading(false)
        alertSuccess(response.data.success)
        // history.replace('/dashboard/categorie')
      }
    } catch (response) {
      setLoading(false)
      setDisable(false)
      alertError(response.data.error)
    }
  }

  return (
    <>
      {loading == true && (
        <div className="loading-dashboard">
          <CircularProgress />
        </div>
      )}
      <Form
        title={'Categorias'}
        handleSubmit={handleSubmit}
        titleButton={'Atualizar'}
        disable={disable}
      >
        <TextField
          id="categorie"
          multiline
          name="categorie"
          label="Categoria de manuais:"
          value={categorie}
          fullWidth
          onChange={(e) => setCategorie(e.target.value)}
        />
      </Form>
    </>
  )
}
