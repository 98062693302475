import React, { useEffect, useState } from 'react'

import Main from '../../Layouts/Questions/Main'
import api from '../../../services/api'
import { getUser } from '../../../services/auth'

import { alertError } from '../../../utils/Alert'

import ButtonWhatsapp from '../../../Components/ButtonWhatsapp'

export default function MainPage() {
  const [manuals, setManuals] = useState([])

  useEffect(() => {
    handleChangeData()
  }, [])

  const handleChangeData = async () => {
    try {
      let dataUser = getUser()
      const { id } = dataUser
      const response = await api.get(`/manuals/${id}`)

      setManuals(response.data)
    } catch (response) {
      alertError(response.data.error)
    }
  }

  return (
    <div className="page-main">
      <ButtonWhatsapp />
      <Main manuals={manuals} />
    </div>
  )
}
