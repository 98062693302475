import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../services/api'
import { getUser } from '../../../services/auth'
import { alertError, alertSuccess, alertInfo } from '../../../utils/Alert'

import FormQuestion from '../../Layouts/Questions/Form'
import ButtonWhatsapp from '../../../Components/ButtonWhatsapp'

import { toast } from 'react-toastify'

export default function QuestionForm() {
  const history = useHistory()
  const [answers, setAnswers] = useState([])
  const [title, setTitle] = useState('')
  const [disable, setDisable] = useState(false)
  const [cover, setCover] = useState('')
  const [control, setControl] = useState(null)

  const handleSubmit = async (e, page) => {
    alertSuccess('Voltando ao perfil!')
    history.replace('/home')
    toast('Acesse seu manual para finalizar a resolução dos módulos')
    // const idCategorie = localStorage.getItem('@BV3/idCategorie')
    // try {
    //   setDisable(true)
    //   if (title === '') {
    //     alertInfo('Por favor, digite o nome do seu manual')
    //     setDisable(false)
    //     return
    //   }

    //   let userData = getUser()
    //   let { id } = userData

    //   var newArray = []

    //   for (var i = 0; i < answers.length; i++) {
    //     if (answers[i] !== undefined) {
    //       newArray.push(answers[i])
    //     }
    //   }

    //   let data = {}
    //   let image = new FormData()

    //   if (cover.preview) {
    //     data = {
    //       user_id: id,
    //       idCategorie,
    //       title,
    //       answers: newArray,
    //       is_finished: false,
    //       page,
    //     }

    //     image.append('file', cover.file, cover.name)
    //   } else {
    //     data = {
    //       user_id: id,
    //       idCategorie,
    //       title,
    //       answers: newArray,
    //       cover,
    //       is_finished: false,
    //       page,
    //     }
    //   }

    //   const response = await api.post('/manual', data)
    //   cover.preview &&
    //     (await api.post(`/cover/${response.data.idManual}`, image))

    //   alertSuccess('Manual salvo com sucesso!')
    //   history.replace('/home')
    // } catch (response) {
    //   setDisable(false)
    //   alertError(response.data.error)
    // }
  }
  const handleSubmitNext = async (e, page) => {
    const idCategorie = localStorage.getItem('@BV3/idCategorie')
    try {
      setDisable(true)
      if (title === '') {
        alertInfo('Por favor, digite o nome do seu manual')
        setDisable(false)
        return
      }

      let userData = getUser()
      let { id } = userData

      var newArray = []
      var imageArray = []

      for (var i = 0; i < answers.length; i++) {
        if (answers[i] !== undefined) {
          newArray.push(answers[i])
          // console.log('ans i', answers[i])
          if (answers[i].image && answers[i].image.length > 0) {
            imageArray.push(answers[i])
          }
        }
      }
      // console.log('img arr', imageArray)
      let data = {}
      let image = new FormData()

      if (cover.preview) {
        data = {
          user_id: id,
          idCategorie,
          title,
          answers: newArray,
          is_finished: false,
          page,
        }

        image.append('file', cover.file, cover.name)
      } else {
        data = {
          user_id: id,
          idCategorie,
          title,
          answers: newArray,
          cover,
          is_finished: false,
          page,
        }
      }
      let manualId
      if (!control) {
        const response = await api.post('/manual', data)
        cover.preview &&
          (await api.post(`/cover/${response.data.idManual}`, image))
        manualId = response.data.idManual
        setControl(response.data.idManual)
      } else {
        manualId = control
        const response = await api.put(`/manual/${control}`, data)
        cover.preview && (await api.post(`/cover/${control}`, image))
      }
      for (var i = 0; i < imageArray.length; i++) {
        // console.log('te', imageArray[i].image)
        for (var j = 0; j < imageArray[i].image.length; j++) {
          if (imageArray[i].image[j] != null) {
            let formImage = new FormData()
            formImage.append('manualId', manualId)
            formImage.append('questionId', imageArray[i].question)
            formImage.append('index_array', imageArray[i].index_array)
            formImage.append('file', imageArray[i].image[j])
            const response = await api.post('/image-question', formImage)
          }
        }
      }

      alertSuccess('Manual salvo com sucesso!', false)
      setDisable(false)
    } catch (response) {
      setDisable(false)
      alertError(response.data.error)
    }
  }
  // console.log('a', control)
  return (
    <>
      <ButtonWhatsapp />
      <FormQuestion
        answers={answers}
        setAnswers={setAnswers}
        title={title}
        setTitle={setTitle}
        handleSubmit={handleSubmit}
        handleSubmitNext={handleSubmitNext}
        disable={disable}
        setDisable={setDisable}
        cover={cover}
        setCover={setCover}
      />
    </>
  )
}
