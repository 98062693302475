import { createContext, useState, useContext } from 'react'

const FilterContext = createContext({})

export const FilterProvider = ({ children }) => {
  const [defaultFilterTipos, setFiltrosTipos] = useState([])
  const [defaultFilterCategorias, setFiltrosCategorias] = useState([])
  const [defaultFilterModulos, setFiltrosModulos] = useState([])

  return (
    <FilterContext.Provider
      value={{
        defaultFilterTipos,
        defaultFilterCategorias,
        defaultFilterModulos,

        setFiltrosTipos,
        setFiltrosCategorias,
        setFiltrosModulos,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilter = () => {
  const context = useContext(FilterContext)
  return context
}
