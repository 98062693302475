import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../services/api'
import { getUser } from '../../../services/auth'
import { alertError, alertSuccess, alertInfo } from '../../../utils/Alert'

import FormQuestion from '../../Layouts/Questions/Form'
import ButtonWhatsapp from '../../../Components/ButtonWhatsapp'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function EditQuestionForm() {
  const history = useHistory()
  const [answers, setAnswers] = useState([])
  const [title, setTitle] = useState('')
  const [cover, setCover] = useState('')
  const [disable, setDisable] = useState(false)
  const [manual, setManual] = useState({})

  useEffect(() => {
    handleChangeAnswers()
  }, [])

  const handleChangeAnswers = async () => {
    try {
      const manual = JSON.parse(localStorage.getItem('@BV3/dataManual'))
      const response = await api.get(`/manual/${manual.id}`)
      // console.log('manual', manual.id)
      // console.log('response', response)

      setTitle(response.data.manual.title)
      setAnswers(response.data.response)
      setManual(response.data.manual)
      setCover(response.data.manual.cover)
    } catch (response) {
      alertError(response.data.error)
    }
  }

  const handleSubmit = async (e, page) => {
    alertSuccess(`Voltando a lista de módulos!`, true)
    // setAnswers([])

    // try {
    //   setDisable(true)

    //   if (title === '') {
    //     alertInfo('Por favor, digite o titulo do manual')
    //     setDisable(false)
    //     return
    //   }

    //   let userData = getUser()
    //   let { id } = userData

    //   var newArray = []

    //   for (var i = 0; i < answers.length; i++) {
    //     if (answers[i] != undefined || answers[i] != null) {
    //       newArray.push(answers[i])
    //     }
    //   }

    //   const manual = JSON.parse(localStorage.getItem('@BV3/dataManual'))

    //   let data = {}
    //   let image = new FormData()

    //   if (cover.preview) {
    //     data = {
    //       user_id: id,
    //       title,
    //       answers: newArray,
    //       is_finished: false,
    //       page,
    //     }

    //     image.append('file', cover.file, cover.name)
    //   } else {
    //     data = {
    //       user_id: id,
    //       title,
    //       answers: newArray,
    //       is_finished: false,
    //       cover,
    //       page,
    //     }
    //   }

    //   const response = await api.put(`/manual/${manual.id}`, data)
    //   cover.preview &&
    //     (await api.post(`/cover/${response.data.idManual}`, image))

    //   alertSuccess(`Manual salvo com sucesso!`, true)
    // } catch (response) {
    //   setDisable(false)
    //   alertError(response.data.error)
    // }
  }
  const handleSubmitNext = async (e, page) => {
    try {
      setDisable(true)

      if (title === '') {
        alertInfo('Por favor, digite o titulo do manual')
        setDisable(false)
        return
      }

      let userData = getUser()
      let { id } = userData

      var newArray = []
      var imageArray = []

      for (var i = 0; i < answers.length; i++) {
        if (answers[i] != undefined || answers[i] != null) {
          newArray.push(answers[i])
          if (answers[i].image && answers[i].image.length > 0) {
            imageArray.push(answers[i])
          }
        }
      }

      const manual = JSON.parse(localStorage.getItem('@BV3/dataManual'))

      let data = {}
      let image = new FormData()

      if (cover.preview) {
        data = {
          user_id: id,
          title,
          answers: newArray,
          is_finished: false,
          page,
        }

        image.append('file', cover.file, cover.name)
      } else {
        data = {
          user_id: id,
          title,
          answers: newArray,
          is_finished: false,
          cover,
          page,
        }
      }

      const response = await api.put(`/manual/${manual.id}`, data)
      cover.preview &&
        (await api.post(`/cover/${response.data.idManual}`, image))
      for (var i = 0; i < imageArray.length; i++) {
        for (var j = 0; j < imageArray[i].image.length; j++) {
          // console.log('x', imageArray[i].image)
          if (imageArray[i].image[j] != null && !imageArray[i].image[j].url) {
            let formImage = new FormData()
            formImage.append('manualId', manual.id)
            formImage.append('questionId', imageArray[i].question)
            formImage.append('index_array', imageArray[i].indexArray)
            formImage.append('file', imageArray[i].image[j])
            const response = await api.post('/image-question', formImage)
          }
        }
      }

      alertSuccess(`Manual salvo com sucesso!`, false)
      setDisable(false)
    } catch (response) {
      setDisable(false)
      alertError(response.data.error)
    }
  }

  return (
    <>
      {manual?.page >= 0 ? (
        <>
          <ButtonWhatsapp />
          <FormQuestion
            answers={answers}
            setAnswers={setAnswers}
            handleChangeAnswers={handleChangeAnswers}
            title={title}
            setTitle={setTitle}
            handleSubmit={handleSubmit}
            handleSubmitNext={handleSubmitNext}
            disable={disable}
            setDisable={setDisable}
            cover={cover}
            setCover={setCover}
            manual={manual}
          />
        </>
      ) : (
        <div className="container-loading">
          <CircularProgress />
        </div>
      )}
    </>
  )
}
