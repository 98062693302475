import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap'

import api from '../../../../services/api'
import { alertError, alertInfo, alertSuccess } from '../../../../utils/Alert'

export default function Forgot() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [visibleAlert, setVisibleAlert] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    document.body.classList.add('bg-default')
    return () => {
      document.body.classList.remove('bg-default')
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setVisibleAlert(false)
      setDisabled(true)
      if (email === '') {
        setDisabled(false)
        alertInfo('Por favor informe o E-mail!')
        return
      }

      const response = await api.post('/admin/forgot-password', {
        email,
      })

      if (response.data.success) {
        setVisibleAlert(true)
        setDisabled(false)
      }

      setEmail('')
    } catch (response) {
      setDisabled(false)
      alertError(response.data.error)
    }
  }

  return (
    <>
      <div className="main-content admin-auth bg-default">
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                   <img src="../../assets/images/logo-white.png" />
                  <h1 className="text-white">Alteração de senha</h1>
                  <p className="text-lead text-light">
                    Informe o E-mail cadastrado para enviar-mos o link de
                    alteração de senha.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100"></div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="8">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  {visibleAlert === true && (
                    <div className="text-center">
                      <p className="alert-success">
                        Verifique sua caixa de E-mail {email}
                      </p>
                    </div>
                  )}
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-mail"
                          type="email"
                          autoComplete="new-email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="group-button-link">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={(e) => handleSubmit(e)}
                        disabled={disabled}
                      >
                        Enviar link
                      </Button>
                      <a
                        className="text-light"
                        onClick={(e) => history.push('/admin/login')}
                      >
                        <small>Voltar</small>
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
