import React from 'react'
import { url } from '../../../services/api'

export default function Cover({ manual }) {
  return (
    <>
      {manual.cover != 1 && manual.cover != 2 && manual.cover != 3 ? (
        <section className="container cover-manual">
          <img src={url + manual.cover} />
        </section>
      ) : (
        <section className={`container cover-manual${manual.cover}`}>
          <img src={`../../../assets/images/cover-manual${manual.cover}.png`} />
          <h1>{manual.title}</h1>
        </section>
      )}
    </>
  )
}
