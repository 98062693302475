export function checkNulls(data) {
  let countNull = 0
  let countKey = 0
  let existeNull = false

  for (var i = 0; i < data.length; i++) {
    for (var [key, value] of Object.entries(data[i])) {
      if (value === '') countNull++
    }
    if (countNull > 0) {
      existeNull = true
    }
  }

  return existeNull
}
