import React, { useEffect, useState } from 'react'
import RegisterModule from '../../Layouts/ModuleLayout/RegisterModule'
import ListModule from '../../Layouts/ModuleLayout/ListModule'

export default function Module() {
  const [modules, setModules] = useState('')
  const [categorie_id, setCategorie_id] = useState('')
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [editMod, setEditMod] = useState(false)
  const [oldModuleName, setOldModuleName] = useState('')

  const handleEditModule = (data) => {
    localStorage.setItem('@BV3/moduleName', data.module)
    setOldModuleName(data.module)
    // console.log(data)
    setEdit(true)
    setEditMod(true)
    setModules(data.module)
    setCategorie_id(data.categorie_id)
    setId(data.id)
  }

  return (
    <>
      <RegisterModule
        modules={modules}
        setModules={setModules}
        categorie_id={[]}
        setCategorie_id={setCategorie_id}
        edit={edit}
        setEdit={setEdit}
        id={id}
        setId={setId}
        editMod={editMod}
        setEditMod={setEditMod}
        oldModuleName={oldModuleName}
        setOldModuleName={setOldModuleName}
      />
      <ListModule
        handleEditModule={handleEditModule}
        oldModuleName={oldModuleName}
        setOldModuleName={setOldModuleName}
      />
    </>
  )
}
