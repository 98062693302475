import React from 'react'

export default function ButtonWhatsapp() {
  return (
    <div className="container-fixed button-whatsapp">
      <div className="container-fixed">
        <a
          className="no-hover-effect"
          href="https://api.whatsapp.com/send?phone=5515981048410&text=Ol%C3%A1!"
          target="_blank"
        >
          <img src="../assets/images/whatsapp.png" />
        </a>
      </div>
    </div>
  )
}
