import Login from '../Views/Pages/Auth/Users/Login'
import Register from '../Views/Pages/Auth/Users/Register'
import Forgot from '../Views/Pages/Auth/Users/Forgot'
import Reset from '../Views/Pages/Auth/Users/Reset'
import LoginAdmin from '../Views/Pages/Auth/Admin/Login'
import ForgotAdmin from '../Views/Pages/Auth/Admin/Forgot'
import ResetAdmin from '../Views/Pages/Auth/Admin/Reset'

import Manual from '../Views/Pages/Manual'

import HomeDashboard from '../Views/Pages/Dashboard/Home'
import Users from '../Views/Pages/Dashboard/User'
import RegisterQuestion from '../Views/Pages/Dashboard/RegisterQuestion'
import ListQuestion from '../Views/Pages/Dashboard/ListQuestion'
import EditQuestion from '../Views/Pages/Dashboard/EditQuestion'
import ListNotValid from '../Views/Pages/Dashboard/ListNotValid'
import ListHistoric from '../Views/Pages/Dashboard/ListHistoric'
import ListResend from '../Views/Pages/Dashboard/ListResend'
import EditManuals from '../Views/Pages/Dashboard/EditManuals'
import Category from '../Views/Pages/Dashboard/Category'
import Module from '../Views/Pages/Dashboard/Module'
import EditCategorie from '../Views/Layouts/Category/EditCategory'

import Main from '../Views/Pages/User/Main'
import Questions from '../Views/Pages/User/Questions'
import EditQuestions from '../Views/Pages/User/EditQuestions'
import PageUpgrade from '../Views/Pages/PageUpgrade'
import TypeManual from '../Views/Layouts/Questions/TypeManual'
import ModuleList from '../Views/Layouts/Questions/ModuleList'

import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DoneAllIcon from '@material-ui/icons/DoneAll'

const routing = {
  public: [
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/cadastro',
      component: Register,
    },
    {
      path: '/forgot',
      component: Forgot,
    },
    {
      path: '/reset/:token',
      component: Reset,
    },
    {
      path: '/admin/login',
      component: LoginAdmin,
    },
    {
      path: '/admin/forgot',
      component: ForgotAdmin,
    },
    {
      path: '/admin/reset/:token',
      component: ResetAdmin,
    },
    {
      path: '/manual/:id/:title',
      component: Manual,
    },
  ],
  admin: [
    {
      path: '/dashboard/editCategorie/:id',
      component: EditCategorie,
    },
    {
      path: '/dashboard',
      exact: true,
      title: 'Home',
      component: HomeDashboard,
      icon: (
        <i
          className="fa fa-home"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
    },
    {
      path: '/dashboard/users',
      exact: true,
      title: 'Usuários',
      component: Users,
      icon: (
        <i
          className="fa fa-user"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
    },
    {
      path: '/dashboard/categories',
      exact: true,
      title: 'Categorias de Manuais',
      component: Category,
      icon: (
        <i
          className="fa fa-list"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
    },
    {
      path: '/dashboard/modules',
      exact: true,
      title: 'Módulos',
      component: Module,
      icon: (
        <i
          className="fa fa-sitemap"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
    },
    {
      title: 'Questões',
      icon: (
        <i
          className="fa fa-question-circle"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
      subComponents: [
        {
          path: '/dashboard/question',
          exact: true,
          title: 'Visualizar Questões',
          icon: <SearchIcon />,
          component: ListQuestion,
        },
        {
          path: '/dashboard/question/register',
          exact: true,
          title: 'Cadastrar Questão',
          icon: <AddIcon />,
          component: RegisterQuestion,
        },
      ],
    },
    {
      path: '/dashboard/question/edit',
      exact: true,
      component: EditQuestion,
    },
    {
      title: 'Manuais',
      icon: (
        <i
          className="fa fa-book"
          aria-hidden="true"
          style={{ marginLeft: 8, marginRight: 20, color: 'white' }}
        ></i>
      ),
      subComponents: [
        {
          path: '/dashboard/manuals',
          exact: true,
          title: 'Manuais recebidos',
          component: ListNotValid,
          icon: <ArrowDownwardIcon />,
        },
        {
          path: '/dashboard/historic',
          exact: true,
          title: 'Manuais finalizados',
          component: ListHistoric,
          icon: <DoneAllIcon />,
        },
        {
          path: '/dashboard/resend',
          exact: true,
          title: 'Manuais reenviados',
          component: ListResend,
          icon: <ArrowDownwardIcon />,
        },
      ],
    },
    {
      path: '/dashboard/manuals/edit',
      exact: true,
      component: EditManuals,
    },
  ],

  user: [
    {
      path: '/home',
      component: Main,
    },
    {
      path: '/form',
      component: Questions,
    },
    {
      path: '/form/edit',
      component: EditQuestions,
    },
    {
      path: '/upgrade',
      component: PageUpgrade,
    },
    {
      path: '/type',
      component: TypeManual,
    },
    {
      path: '/module',
      component: ModuleList,
    },
  ],
}

export default routing
