import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    padding: theme.spacing(2, 3, 4),
    width: 300,
  },
  close: {
    fontSize: 20,
    textAlign: 'right',
    width: '100%',
    cursor: 'pointer',
  },
  qrcode: {
    margin: (0, 30),
  },
}))

export default useStyles
